import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import ConfirmDialog from '../../components/confirm';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let JWT = cookies.get('JWT');
const downloadFile = (id, name) => () => {
    let url = `/api/file/${id}/streams`;
    var link = document.createElement("a");
    link.setAttribute('download', name);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export default function DataTable({ files, printers, onChange = () => { } }) {
    const { projectId } = useParams();

    const [dialog, setDialog] = useState({});
    const columns = [
        {
            field: 'id', headerName: 'Preview', width: 100, renderCell: (params) => {
                const fileType = params.row.name.split('.').pop();
                switch (fileType) {
                    case 'gcode':
                        return <Avatar
                            alt={params.row.name}
                            variant="rounded"
                            src={'/api/file/' + params.row.id + '/preview'}
                            sx={{ width: 56, height: 56 }}
                        >
                            <TextSnippetIcon fontSize="large" />
                        </Avatar>
                    case 'stl':
                    case 'obj':
                        return <Avatar
                            alt={params.row.name}
                            variant="rounded"
                            src={'/api/file/' + params.row.id + '/preview'}
                            sx={{ width: 56, height: 56 }}
                        />
                    default:
                        return <Avatar
                            alt={params.row.name}
                            variant="rounded"
                            src={'/api/file/' + params.row.id + '/preview'}
                            sx={{ width: 56, height: 56 }}
                        />
                }
            }
        },
        { field: 'name', headerName: 'Name', width: 500 },
        { field: 'mimetype', headerName: 'Type', width: 200, valueGetter: (params) => params.row.name.split('.').pop(), },
        {
            field: 'size',
            headerName: 'Size',
            type: 'number',
            width: 130,
            // valueGetter: (params) => getReadableFileSizeString(params.row.size),
            renderCell: (params) => getReadableFileSizeString(params.row.size)
        }, {
            field: 'actions',
            headerName: 'Actions',
            width: 130,
            renderCell: (params) => {
                const fileType = params.row.name.split('.').pop();

                return <>
                    <IconButton aria-label="delete" onClick={deleteFile(params.row.id, params.row.name)}>
                        <DeleteIcon fontSize="large" />
                    </IconButton>
                    <IconButton aria-label="download" onClick={downloadFile(params.row.id, params.row.name)}>
                        <DownloadIcon fontSize="large" />
                    </IconButton>
                    {(fileType === 'stl' || fileType === 'obj') && <IconButton aria-label="download" href={'/slicer/' + params.row.projectId + '/?fileId=' + params.row.id}>
                        <PrintIcon fontSize="large" />
                    </IconButton>}
                    {fileType === 'gcode' && printers.length > 0 && <IconButton aria-label="download" onClick={sendToPrinter(params.row.id)}>
                        <PrintIcon fontSize="large" />
                    </IconButton>}
                </>
            },
        },
    ];

    const sendToPrinter = fileId => () => {
        let printerId = printers[0].id
        if (printers.length === 1) {

        }
        const body = {
            id: printerId,
            command: 'print',
            params: {
                fileId: fileId,
				jwt: JWT
            }
        }

        let url = `/api/customerprinter/printercommands/`
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            return res.json();
        }).then(resp => {
            console.log(resp)
        }).catch(e => {
            console.error(e);
        });
    }

    const deleteFile = (id, name) => () => {
        let opt = {
            open: true,
            title: "Delete File",
            msg: "Are you sure you want to delete this file, This can't be undone.",
            data: { type: 'deleteFile', id}
        }
        setDialog(opt)
    }

    const handleConfirm = (data) => {
        const { type, id } = data;
        if (type === 'deleteFile') {
            fetch('/api/file/' + id, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(function (res) {
                return res.json();
            }).then(resp => {
                console.log(resp);
                onChange();
            });
        }
        setDialog({ open: false });
    }

    const handleCancel = () => {
        setDialog({ open: false });
    }
    return (
        <div style={{ height: 600, width: '100%', fontSize: '3rem !important' }}>
            <DataGrid
                rows={files}
                columns={columns}
                density='comfortable'
                sx={{ fontSize: '3rem !important' }}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    },
                }}
                pageSizeOptions={[5, 10, 20, 50]}
            // checkboxSelection
            />
            <ConfirmDialog onConfirm={handleConfirm} onCancel={handleCancel}  {...dialog} />
        </div>
    );
}


const getReadableFileSizeString = (fileSizeInBytes) => {
    if (!fileSizeInBytes) {
        return "";
    }
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB'];
    do {
        fileSizeInBytes /= 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}