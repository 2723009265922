import React, { useState, useEffect } from 'react';
import './App.css';
import { AppRoutes, UnsecuredRoutes } from './Routes';
import sockets from './utils/ws';
import { SnackbarProvider } from 'notistack';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

let wsClient;
let initialUser = localStorage.getItem('user') ||
  cookies.get('USER') || { userId: 'Guest', loggedIn: false };
if (initialUser && typeof initialUser === 'string') {
  initialUser = JSON.parse(initialUser);
  let JWT = cookies.get('JWT');
  // let JWT = initialUser.JWT;
  if (!JWT) {
    let cookieOpts = { path: '/', sameSite: 'strict', secure: true };
    if (process.env.NODE_ENV !== 'production') {
      delete cookieOpts.sameSite;
      delete cookieOpts.secure;
    }
    cookies.set('JWT', initialUser.jwt, cookieOpts);
  }
}
function App() {
  const [userData, setUserData] = useState(initialUser);

  useEffect(() => {
    if (userData.userId && userData.loggedIn === true) {
      fetch(`/api/user/${userData.userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((resp) => resp.json())
        .then(
          async (result) => {
            setUserData({ ...userData, ...result });
          },
          (error) => {
            console.log('error: ', error);
          }
        );
      const url = window.location.protocol + '//' + window.location.host.replace(':3000',':10112');
      wsClient = sockets.init(url,userData.jwt);
    }
    //eslint-disable-next-line
  }, []);
  return (
    <div className='App'>
      <SnackbarProvider style={{fontSize:'2.5rem'}}>
        {userData.loggedIn !== true ? (
          <UnsecuredRoutes />
        ) : (
          <AppRoutes userData={userData} ws={wsClient} />
        )}
      </SnackbarProvider>
    </div>
  );
}

export default App;
