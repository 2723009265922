import React, { useEffect, useRef, useTransition } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import PrinterIcon from '@mui/icons-material/Print';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate } from 'react-router-dom';

const StyledButton = styled(IconButton)({
    color: 'black',
    opacity: 1
});



export default function ProjectCard({ user, project, onChange = () => { } }) {
    const imageRef = useRef();
    const { name, files, description = '', id, _system } = project;
    const [isPending, startTransition] = useTransition()
    const { created } = _system;
    let fileId = 'temp';
    if (files[0]) {
        fileId = files[0].id;
    }
    const navigate = useNavigate();
    const goToProject = () => {
        startTransition(() => {
            navigate(`/slicer/${id}`);
        });
    }

    const deleteProject = () => {
        fetch('/api/project/' + id, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(function (res) {
            return res.json();
        }).then(jobData => {
            console.log(jobData)
            onChange();
        });
    }

    useEffect(() => {
        if (project.updated === true) {
            imageRef.current.src = `/api/file/${fileId}/preview`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.updated])

    const handleImageError = (e) => {
        let index = Number(e.target.dataset.index);
        index++;
        if (index < project.files.length) {
            e.target.src = '/api/file/' + project.files[index].id + '/preview'
            e.target.dataset.index = index;
        } else {
            e.target.src = '/mstile-144x144.png'
            e.target.onerror = null;
        }
    }

    return (
        <Card sx={{ position: 'relative', maxWidth: 350, maxHeight: 350, margin: '0 10px', backgroundColor: project.color, borderRadius: 5, boxShadow: 'none', marginBottom: '4rem' }}>
            <Link to={'/project/' + id}>
                <CardMedia
                    component="img"
                    height="194"
                    ref={imageRef}
                    image={`/api/file/${fileId}/preview`}
                    alt={name}
                    data-index="0"
                    onError={handleImageError}
                    style={{ objectFit: 'contain', height: '100%', padding: 50 }}
                /></Link>
            <CardContent sx={{ position: 'absolute', width: '100%', bottom: 10, left: 0, padding: '10px 25px', opacity: 1, textAlign: 'left' }}>
                <Typography variant="h3" color="black">
                    {name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            <CardActions disableSpacing sx={{
                position: 'absolute', top: 0, right: 10, paddingTop: '16px', opacity: 1, height: '100%', color: 'black !important', '& button': { opacity: 1, fontSize: '3rem' }
            }} >
                <Grid container direction={'column'} justifyContent="right" sx={{ height: '100%' }
                } >
                    <Grid item xs={3}>
                        <Avatar sx={{ border: '1px white solid', bgcolor: 'yellow', color: 'black', fontSize: '2rem' }} src={user.avatar || '/mstile-70x70.png'} aria-label="model">
                            {name.slice(0, 1).toUpperCase()}
                        </Avatar>
                    </Grid>
                    <Grid item xs={2}>
                        <StyledButton aria-label="add to favorites" onClick={goToProject}>
                            <PrinterIcon fontSize="xlarge" />
                        </StyledButton>
                    </Grid>
                    <Grid item xs={2}>
                        <StyledButton aria-label="share">
                            <ShareIcon fontSize="xlarge" />
                        </StyledButton>
                    </Grid>
                    <Grid item xs={3}>
                        <StyledButton aria-label="Delete Project" onClick={deleteProject}>
                            <DeleteIcon fontSize="xlarge" />
                        </StyledButton>
                    </Grid>
                    <Grid item xs={1}>
                        <StyledButton aria-label="settings">
                            <MoreVertIcon fontSize="xlarge" />
                        </StyledButton>
                    </Grid>
                </Grid>
            </CardActions >
        </Card >
    );
}