import React from 'react';
// Custom components
import SideNav from '../sideNav';
// Material UI
import { styled } from '@mui/material/styles';

//#region Styled components
const Wrapper = styled('div', { label: 'routes-wrap' })({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  margin: '0',
  padding: '0',
  width: '100%',
});
//#endregion

export default function RoutesWrapper(props) {
  return (
    <Wrapper>
      <SideNav />
      {props.children}
    </Wrapper>
  );
}
