import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
// Custom components
import LandingComp from '../../components/landing';
// Material UI

//#region Styled components
const Wrapper = styled('div', { label: 'pg-home-wrap' })({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  width: '100%',
});

//#endregion
export default function Landing(props) {
  return (
    <Wrapper>
      <LandingComp />
    </Wrapper>
  );
}
