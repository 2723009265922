import React from 'react';
import styled from '@emotion/styled';
// Custom components
// Material UI
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
// React Router
import { Link } from 'react-router-dom';

//#region Styled components
const BtnWrapper = styled('div', { label: 'landing-btn-wrap' })({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '2rem',
  justifyContent: 'center',
  width: '100%',
});

const StyledBtn = styled(ButtonBase, { label: 'landing-btn' })({
  width: '27.75rem',
  '&:hover': {
    boxShadow: '0.5rem 0.5rem 1.5rem #0f0f0f',
  },
});

const StyledTxt = styled(Typography, { label: 'landing-txt' })({
  color: '#2c5673',
  fontWeight: '600',
  letterSpacing: '0.2px',
  lineHeight: '6.75rem',
  marginBottom: '9rem',
  width: '30.2vw',
  '&.credits': {
    color: '#06c',
    fontSize: '1.25rem',
    marginTop: '3.25rem',
    opacity: '0.26',
  },
  '&.subtitle': {
    fontSize: '3rem',
    lineHeight: '5rem',
    marginBottom: '8.25rem',
  },
});

const Wrapper = styled('div', { label: 'landing-wrap' })({
  alignItems: 'center',
  backgroundColor: '#bdbdbd',
  backgroundImage: 'url(/images/flipped-nasa-bg.png)',
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '75%',
  color: '#333',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  paddingTop: '22rem',
  width: '100%',
});
//#endregion

export default function LandingComp(props) {
  return (
    <Wrapper>
      <StyledTxt variant='h2'>
        From beginner to expert, PrintBed offers everything you need to bring
        your 3D printing ideas to life
      </StyledTxt>
      <StyledTxt className='subtitle' variant='h2'>
        Join us now to unleash your creativity
      </StyledTxt>
      <BtnWrapper>
        <StyledBtn component={Link} to={'/login'} variant='filled'>
          Login
        </StyledBtn>
        <StyledBtn component={Link} to={'/register'} variant='outlined'>
          Register
        </StyledBtn>
      </BtnWrapper>
      <StyledTxt
        className='credits'
        component='a'
        href='https://sketchfab.com/3d-models/mantis-nasa-mech-2030-space-program-108773e6b4674e69a5922dd5f59f1a6e'
        target='_blank'
      >
        Image source: SketchFab, David Young
      </StyledTxt>
    </Wrapper>
  );
}
