import React from 'react';
import styled from '@emotion/styled';
// Custom components
import LoginComp from '../../components/login';
// Material UI

//#region Styled components

//#endregion

export default function LoginPg(props) {
  return <LoginComp />;
}
