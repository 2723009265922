import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useLoader, useThree } from '@react-three/fiber';
// import { OrthographicCamera, PerspectiveCamera } from 'three';

// import CircularProgress from '@mui/material/CircularProgress';


import Printbed from './printbed';
import Models from './model';
import CutPlanes from './cutPlanes';

import { OrbitControls, TransformControls } from '@react-three/drei';
import { STLExporter } from 'three/addons/exporters/STLExporter.js';
import * as THREE from 'three';
import Emitter from '../../utils/events';
import { GCodeModel } from "@sunsh1n3/react-gcode-viewer";

export default function NewSlicer(props) {
    const { activeTool,
        scale,
        cut,
        files,
        printerVolume = [220, 220, 250],
        printerShape = 'rectangular',
        gcodeFile,
        clip,
        mode,
        onModelVolume,
        onSelected,
        onMaxZ,
        onDeselected,
        exportStage } = props;
    const groupRef = useRef(null);
    const clippingPlaneRef = useRef(null);
    const controls = useRef();
    const transformControls = useRef();
    const containerRef = useRef(null);
    const loadedModels = useRef({});
    const [groupVisible, setGroupVisible] = useState(false);
    const [modelVolume, setModelVolume] = useState([50, 50, 50]);
    const cameraPosition = [printerVolume[0] / 2, -160.80699123665784, 9.59712260947532];
    const position = new THREE.Vector3().fromArray(cameraPosition);
    const quaternionArray = [0.6943979365591241, -0.010549725766747673, -0.010930041322482664, 0.7194308467013115];
    const quaternion = new THREE.Quaternion().fromArray(quaternionArray);
    const stateMaterials = useLoader(THREE.TextureLoader, ['/models/matcaps/1024/3E3E3E_AEAEAE_848484_777777.png', '/models/matcaps/1024/9B4816_E8A138_CC7421_DC8827.png', '/models/matcaps/1024/346088_6ABED7_56A0C5_4E91B8.png'])
    const cameraRef = useRef();

    const setUp = (state) => {
        const { camera } = state;
        cameraRef.current = camera;
    }

    useEffect(() => {
        Emitter.on('rotate', handleRotate);
        Emitter.on('setView', handleView);
        return () => {
            Emitter.off('rotate', handleRotate);
            Emitter.off('setView', handleView);
        }
    }, []);


    const handleRotate = (dir) => {
        const camera = cameraRef.current;
        const orbitControls = controls.current;
        const currentPosition = camera.position.clone();
        const targetPosition = orbitControls.target.clone();
        let angle = THREE.MathUtils.degToRad(5);
        if (dir === 'left') {
            angle *= -1;
        }
        const rotationMatrix = new THREE.Matrix4().makeRotationZ(angle);
        const newPosition = currentPosition.sub(targetPosition).applyMatrix4(rotationMatrix).add(targetPosition);
        camera.position.copy(newPosition);
        camera.lookAt(targetPosition);
        camera.updateProjectionMatrix();
        Emitter.emit('invalidate');
    }

    const handleView = async (view) => {
        const camera = cameraRef.current;
        const orbitControls = controls.current;
        const targetPosition = orbitControls.target.clone();
        //camera reset
        camera.position.copy(position);
        camera.lookAt(targetPosition);
        camera.updateProjectionMatrix();


        const currentPosition = camera.position.clone();
        let rotationMatrix;
        switch (view) {
            case 'iso':
                rotationMatrix = new THREE.Matrix4().makeRotationY(THREE.MathUtils.degToRad(0));
                break;
            case 'top':
                rotationMatrix = new THREE.Matrix4().makeRotationX(-THREE.MathUtils.degToRad(90));
                break;
            case 'left':
                rotationMatrix = new THREE.Matrix4().makeRotationZ(THREE.MathUtils.degToRad(90));
                break;
            case 'right':
                rotationMatrix = new THREE.Matrix4().makeRotationZ(-THREE.MathUtils.degToRad(90));
                break;
            case 'bottom':
                rotationMatrix = new THREE.Matrix4().makeRotationX(THREE.MathUtils.degToRad(90));
                break;
            default:
                break;
        }
        const newPosition = currentPosition.sub(targetPosition).applyMatrix4(rotationMatrix).add(targetPosition);
        camera.position.copy(newPosition);
        camera.lookAt(targetPosition);
        camera.updateProjectionMatrix();
        Emitter.emit('invalidate');
    }

    const exportFiles = (printerCenter = false) => {
        if (groupRef.current) {
            let exporter = new STLExporter();
            let group = new THREE.Group();


            let fileGroup = groupRef.current;
            group.position.set(0, 0, 0);
            fileGroup.traverse(obj => {
                if (obj.type === "Mesh") {
                    const geometry = obj.geometry;

                    // Clone the geometry to avoid modifying the original
                    const geometryCopy = geometry.clone();

                    // Get the matrix of the parent group, if there is one
                    const groupMatrix = obj.parent ? obj.parent.matrixWorld : new THREE.Matrix4();

                    // Combine the matrices of the parent group and the mesh to get the final matrix
                    const matrix = new THREE.Matrix4().multiplyMatrices(groupMatrix, obj.matrix);

                    // Apply the transformations to the vertices of the cloned geometry using the final matrix
                    geometryCopy.applyMatrix4(matrix);

                    const mesh = new THREE.Mesh(geometryCopy, obj.material);
                    if (printerCenter !== true) {
                        mesh.position.x -= printerVolume[0] / 2;
                        mesh.position.y -= printerVolume[1] / 2;
                    }
                    mesh.updateMatrix();
                    mesh.geometry.applyMatrix4(mesh.matrix);
                    mesh.matrix.identity();
                    group.add(mesh);
                    // fileGroup.parent.add(mesh);
                }
            });
            let stlData = exporter.parse(group, { binary: true });
            var blob = new Blob([stlData.buffer], { type: "application/octet-stream" });
            return blob
        } else {
            return null;
        }
    }


    if (exportStage?.current) {
        exportStage.current = {
            exportFiles
        }
    }

    const modelLoaded = (id) => {
        loadedModels.current[id] = true;
        let allLoaded = files.length > 0;
        files.forEach(file => {
            if (loadedModels.current[file.id] !== true) {
                allLoaded = false;
            }
        });
        if (allLoaded === true) {
            //the meshes are loaded but the group still shows a position of Infinity unless you wait.
            setTimeout(() => alignToBed(groupRef.current), 200);
        }
    }

    useEffect(() => {
        if (onModelVolume) {
            onModelVolume(modelVolume);
        }
    }, [modelVolume]);

    const handleChangeTransform = (event) => {
        // console.log(event)
        if (activeTool !== 'select' && groupRef.current) {
            alignToBed(groupRef.current);
            const bbox = new THREE.Box3().setFromObject(groupRef.current);
            const size = new THREE.Vector3();
            bbox.getSize(size);
            setModelVolume([size.x, size.y, size.z]);
        }
    }

    useEffect(() => {
        handleChangeTransform();
    }, [scale])

    const alignToBed = (group) => {
        // console.log('align to bed', group)
        if (group && group.userData.centered !== true) {
            group.userData.centered = true

            const bbox = new THREE.Box3().setFromObject(group);
            const size = new THREE.Vector3();
            bbox.getSize(size);
            setModelVolume([size.x, size.y, size.z]);

            group.traverse(mesh => {
                if (mesh.type === "Mesh" && mesh.userData.centered !== true) {
                    let meshbBox = new THREE.Box3().setFromObject(mesh); // Compute the bounding box of the mesh
                    const meshCenter = meshbBox.getCenter(new THREE.Vector3());
                    mesh.geometry.center();
                    mesh.position.x = meshCenter.x;
                    mesh.position.y = meshCenter.y;
                    mesh.position.z = meshCenter.z;
                }
            });


            const center = bbox.getCenter(new THREE.Vector3());
            group.position.sub(center);

            const nbbox = new THREE.Box3().setFromObject(group);
            const offset = nbbox.min.z;
            group.position.x = printerVolume[0] / 2;
            group.position.y = printerVolume[1] / 2;
            group.position.z = -offset;

            group.position.sub(center);
            // group.updateMatrixWorld(true);
            setGroupVisible(true);
        } else {
            const bbox = new THREE.Box3().setFromObject(group);
            const offset = bbox.min.z;
            const size = new THREE.Vector3();
            bbox.getSize(size);
            setModelVolume([size.x, size.y, size.z]);
            // group.position.x = printerVolume[0] / 2;
            // group.position.y = printerVolume[1] / 2;
            group.position.z -= offset
        }
    }

    const style = {
        width: '100%', height: '100%', overflow: 'hidden'
    }
    return <div style={{ width: '100%', height: '100%', overflow: 'hidden' }} ref={containerRef}>
        <Canvas frameloop="demand" shadows gl={{ localClippingEnabled: true }} camera={{ position: position, quaternion: quaternion, up: new THREE.Vector3(0, 0, 1), near: 0.1, far: 5000, fov: 75 }} onCreated={setUp}>
            <ambientLight />
            <InvalidateControl />
            <pointLight position={[10, 10, 10]} />
            <Printbed printerVolume={printerVolume} printerShape={printerShape} />
            <group ref={groupRef} visible={groupVisible && mode === 'model'}>
                {files && files.length > 0 &&
                    <Models
                        files={files}
                        onLoad={modelLoaded}
                        onSelected={onSelected}
                        onDeselected={onDeselected}
                        stateMaterials={stateMaterials}
                        transformControls={transformControls}
                        controls={controls}
                        activeTool={activeTool}
                        scale={scale} />}
            </group>
            {mode === 'preview' ?
                <GCodeModel
                    floorProps={{
                        gridLength: printerVolume[0],
                        gridWidth: printerVolume[1]
                    }}
                    layerColor="#008675"
                    topLayerColor="#e79f0d"
                    quality={1}
                    clip={clip}
                    // showAxes
                    style={style}
                    url={`/api/file/${gcodeFile.id}/streams`}
                    onFinishLoading={(data, maxZ) => {
                        onMaxZ(Math.ceil(maxZ))
                    }}
                />
                : null}
            {activeTool === 'cut' && <CutPlanes cut={cut} printerVolume={printerVolume} modelVolume={modelVolume} />}
            <plane ref={clippingPlaneRef} attach="clippingPlanes-0" normal={[0, -1, 0]} constant={clip} />

            {/* <ClipPlane ref={clippingPlaneRef} clip={clip} modelVolume={modelVolume} /> */}
            <TransformControls position={[0, -1000, 0]} ref={transformControls} onChange={handleChangeTransform} rotationSnap={THREE.MathUtils.degToRad(15)}></TransformControls>
            <OrbitControls ref={controls} target={new THREE.Vector3(printerVolume[0] / 2, printerVolume[1] / 2, 0)} />
        </Canvas>
    </div >
}

const InvalidateControl = () => {
    const { invalidate } = useThree();
    useEffect(() => {
        Emitter.on('invalidate', invalidate);
        return () => {
            Emitter.off('invalidate', invalidate);
        }
    }, []);
    return null;
}