import React from 'react';
import styled from '@emotion/styled';
// Custom components
import RegisterComp from '../../components/register';
// Material UI

//#region Styled components

//#endregion

export default function Register(props) {
  return <RegisterComp />;
}
