

import React, { useRef, useState, useEffect } from 'react'
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import * as THREE from 'three';
import Emitter from '../../utils/events';
const StlObject = ({ file, activeTool, scale, controls, onDeselected, onSelected, transformControls, onLoad = () => { }, stateMaterials }) => {
    const [selected, SetSelected] = useState(false);
    const [hoverState, SetHoverState] = useState(false);
    // const [modelLoaded, SetModelLoaded] = useState(false);
    const modelLoaded = useRef(false);
    const [localScale, SetLocalScale] = useState();
    const meshRef = useRef();
    let url = `/api/file/${file.id}/streams`;
    const geometry = useLoader(STLLoader, url);
    const [inActive, Active, Hover] = stateMaterials;
    useEffect(() => {
        Emitter.on(file.id, handleEvent);

        return () => {
            Emitter.off(file.id, handleEvent);
        }
    }, []);


    const handleEvent = (data) => {
        const { type } = data;
        if (type === 'click') {
            handleClick();
        }
    }

    const handleClick = (e) => {
        SetSelected(true);
        onSelected(file.id);
    };

    useEffect(() => {
        if (selected === true) {
            console.log(activeTool);
            if (activeTool === 'move') {
                controls.current.enabled = false;
                transformControls.current.setMode('translate');
                transformControls.current.attach(meshRef.current);
            } else if (activeTool === 'rotate') {
                controls.current.enabled = false;
                transformControls.current.setMode('rotate')
                transformControls.current.attach(meshRef.current);
            } else if (activeTool === 'resize') {
                controls.current.enabled = false;
                transformControls.current.setMode('scale')
                transformControls.current.attach(meshRef.current);
            } else {
                if (transformControls.current)
                    transformControls.current.detach();
                if (controls.current)
                    controls.current.enabled = true;
            }
        }
    }, [activeTool, selected]);

    useEffect(() => {
        if (selected === true) {
            SetLocalScale(scale);
        }
    }, [scale])

    const update = (mesh) => {
        if (!modelLoaded.current) {
            // SetModelLoaded(true)
            modelLoaded.current = true
            onLoad(file.id);
        }
    }

    const hoverIn = () => {
        if (selected === false) {
            SetHoverState(true)
        }
    }

    const hoverOut = () => {
        if (selected === false) {
            SetHoverState(false)
        }
    }

    const clearSelected = (e) => {
        if (selected === true && e.shiftKey !== true) {
            SetSelected(false);
            SetHoverState(false);
            onDeselected(file.id);
            if (transformControls.current)
                transformControls.current.detach();
            if (controls.current)
                controls.current.enabled = true;
        }
    }

    const getState = () => {
        if (selected === true) {
            return Active;
        } else if (hoverState === true) {
            return Hover;
        } else {
            return inActive;
        }
    }

    const handleContextMenu = (e) => {
        e.fileId = file.id;
        Emitter.emit('contextMenu', e);
    }


    return (
        <mesh onUpdate={update} name={file.name || 'model'} scale={localScale} userData={{ selected, id: file.id }} geometry={geometry} ref={meshRef} onClick={handleClick} onContextMenu={handleContextMenu} onPointerEnter={hoverIn} onPointerLeave={hoverOut} onPointerMissed={clearSelected}>
            <meshMatcapMaterial matcap={getState()} />
        </mesh>
    );
}

const Models = (props) => {
    const { files } = props;
    return files.map(file => {
        return <StlObject key={file.id} file={file} {...props} />
    });

}

export default Models;