import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import theme from './theme.jsx';
import { SettingsComps } from '@sunsh1n3/slicer-comps';
import PrinterService, { SETTING_LEVEL } from './service.js';
import { localOverides } from '../../utils/common';

// const mainTheme = createTheme(theme);
// const svc = new PrinterService();
// svc.init();

let svc;

function settingIntToStr(lvlInt) {
    let lvlStr = '';
    switch (parseInt(lvlInt)) {
        case 0:
            lvlStr = 'beginner';
            break;
        case 1:
            lvlStr = 'advanced';
            break;
        case 2:
            lvlStr = 'expert';
            break;
        default:
            lvlStr = 'beginer';
            break;
    }
    return lvlStr;
}

function Loader(props) {
    const { printer } = props;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (printer) {
            console.log('creating svc')
            svc = new PrinterService(printer.settings);
            svc.init();
            setLoading(false)
        }
    }, [printer]);
    return <>{(props.printer && loading === false) ? <App {...props} /> : null}</>
}

function App({ onChange = () => { }, printer }) {
    const [count, setCount] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [testVal, setTestVal] = useState(0);
    const [menuSelected, setMenuSelected] = useState(false);
    const [settingVals, setSettingVals] = useState([]);

    //#region from slicer-settings-test
    const LEVEL_STRINGS = ['beginner', 'advanced', 'expert'];
    const [options, setOptions] = useState({});
    const [selectedLevel, setSelectedLevel] = useState(SETTING_LEVEL.advanced);
    const [settings, setSettings] = useState([]);
    const categories = svc.categories;
    const allSettings = svc.getAllSettings();
    const [overrides, setOverrides] = useState(localOverides);

    useEffect(() => {
        let tempOpts = {};
        let tempSettings = [];
        switch (selectedLevel) {
            case SETTING_LEVEL.expert:
                tempSettings = svc.expertSettings;
                tempOpts = svc.expertOptions;
                break;
            case SETTING_LEVEL.advanced:
                tempSettings = svc.advancedSettings;
                tempOpts = svc.advancedOptions;
                break;
            case SETTING_LEVEL.beginner:
            default:
                tempSettings = svc.beginnerSettings;
                tempOpts = svc.beginnerOptions;
                break;
        }
        setSettings(tempSettings);
        setOptions(tempOpts);
    }, [selectedLevel]);

    useEffect(() => {
        onChange(overrides);
    }, [overrides])

    function handleLevelChange(event) {
        setSelectedLevel(SETTING_LEVEL[settingIntToStr(event.target.value)]);
    }

    function shouldRenderChildren(targetSetting, categoryName) {
        return Object.keys(targetSetting.settingObj.children).some((childKey) =>
            options[categoryName].includes(childKey)
        );
    }

    function renderSetting(
        targetSetting,
        categoryName,
        targetSettingKey,
        recurse = true
    ) {
        if (
            targetSetting?.hasChildren &&
            recurse &&
            shouldRenderChildren(targetSetting, categoryName)
        ) {
            return (
                <SettingsComps.SettingsFolder
                    key={targetSettingKey}
                    label={targetSetting.settingObj.label ?? 'Label'}
                // label={
                //   targetSetting.settingObj.type === 'category'
                //     ? targetSetting.settingObj.label ?? 'Label'
                //     : renderSetting(
                //         targetSetting,
                //         categoryName,
                //         targetSettingKey,
                //         false
                //       )
                // }
                >
                    {targetSetting.settingObj.type && renderComponent(targetSettingKey, targetSetting)}
                    {Object.keys(targetSetting.settingObj.children).map((childKey) => {
                        if (options[categoryName].includes(childKey)) {
                            return renderSetting(
                                allSettings[childKey],
                                categoryName,
                                childKey
                            );
                        } else {
                            return null
                        }
                    })}
                </SettingsComps.SettingsFolder>
            );
        } else if (targetSetting) {
            return renderComponent(targetSettingKey, targetSetting)
        }
    }

    const renderComponent = (targetSettingKey, targetSetting) => {
        switch (targetSetting.settingObj.type) {
            case 'bool':
                return (
                    <SettingsComps.LabeledCheckbox
                        key={targetSettingKey}
                        align="left"
                        label={targetSetting.settingObj.label}
                        defaultChecked={localOverides[targetSettingKey] || printer.overrides[targetSettingKey]?.default_value || targetSetting.settingObj.default_value}
                        onChange={(newVal, e) => {
                            setOverrides(prev => {
                                let tmp = { ...prev };
                                tmp[targetSettingKey] = newVal.split('=')[1] === "true";
                                return tmp
                            });
                        }}

                    />
                );
                break;
            case 'str':
                break;
            case 'enum':
                let vals = Object.values(targetSetting.settingObj.options);
                let keys = Object.keys(targetSetting.settingObj.options);
                return (
                    <div key={targetSettingKey} style={{ padding: '1.5rem 1rem', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography
                            align="left"
                            variant="body2"
                            sx={{
                                flexGrow: '1',
                                marginLeft: '4.25rem',
                            }}>
                            {targetSetting.settingObj.label}
                        </Typography>
                        <SettingsComps.Dropdown
                            key={targetSettingKey}
                            align="left"
                            values={vals}
                            defaultSelectedIndex={keys.indexOf(localOverides[targetSettingKey] || '') || 0}
                            onChange={(newVal) => {
                                setOverrides(prev => {
                                    let tmp = { ...prev };
                                    let optionKey = getKeyByValue(targetSetting.settingObj.options, newVal.split('=')[1]);
                                    tmp[targetSettingKey] = optionKey;
                                    return tmp
                                });
                            }}
                        />
                    </div>
                );
                break;
            case 'float':
            case 'int':
                return (
                    <SettingsComps.SettingInput
                        onChange={(newVal, e) => {
                            setOverrides(prev => {
                                let tmp = { ...prev };
                                tmp[targetSettingKey] = newVal.split('=')[1];
                                return tmp
                            });
                        }}
                        key={targetSettingKey}
                        align="left"
                        unitLabel={targetSetting.settingObj.unit || ''}
                        settingKey={targetSettingKey}
                        settingLabel={targetSetting.settingObj.label}
                        initValue={localOverides[targetSettingKey] || printer.overrides[targetSettingKey]?.default_value || targetSetting.settingObj.default_value}
                    />
                );
                break;
            default:
                return <p>{targetSetting.settingObj.label}</p>;
        }
    }
    //#endregion

    return (
        <Box sx={{ padding: '0 0 0 10px', backgroundColor: '#f3f3f3' }}>
            {categories.map((category) => {
                if (!options[category.key]) {
                    return '';
                } else {
                    return (
                        <SettingsComps.SettingsFolder label={category.label}>
                            {options[category.key]?.map((settingKey) => {
                                return renderSetting(
                                    allSettings[settingKey],
                                    category.key,
                                    settingKey
                                );
                            })}
                        </SettingsComps.SettingsFolder>
                    );
                }
            })}
        </Box>
    );
}

export default Loader;


const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}

