/** @format */

import React, { useState } from 'react'

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import NearMeIcon from '@mui/icons-material/NearMe';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import SyncIcon from '@mui/icons-material/Sync';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LayersIcon from '@mui/icons-material/Layers';
import TextureIcon from '@mui/icons-material/Texture';
import BorderInnerIcon from '@mui/icons-material/BorderInner';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import SettingsIcon from '@mui/icons-material/Settings';
import CutIcon from '@mui/icons-material/ContentCut';

const boxSize = 49.5;
const position = 9.9; // boxSize * .2

const Styles = {
    root: {
        width: '100%',
        // height: 56,
        position: 'absolute',
        display: 'flex',
        bottom: 30,
        zIndex: 100
    },
    tools: {
        // width: 400,
        height: '100%',
        backgroundColor: 'black',
        borderRadius: 28,
        display: 'flex',
        alignSelf: 'center',
        margin: 'auto',
        color: 'white',
    },
    button: {
        // color: 'white',
        margin: '.25em .5em',
    },
    toolTip: {
        fontSize: '2em'
    },
    active: {
        borderColor: '#174CFF',
    },
};

const Views = ({ activeTool, onChange }) => {
    // const [activeView, setActiveView] = useState('iso')
    // const [activeTool, setActiveTool] = useState('select');

    const setTool = e => {
        e.stopPropagation();
        const tool = e.target.dataset.tool;
        if (tool) {
            if (onChange) {
                onChange(tool)
            }
        }
    }

    return (
        <div style={Styles.root} >
            <div style={Styles.tools}>
                <Tooltip title={<Typography fontSize={12}>Select (esc)</Typography>}>
                    <IconButton onClick={setTool} color={(activeTool === 'select') ? "primary" : "inherit"} data-tool="select" aria-label="Select" size="large" style={Styles.button} ><NearMeIcon data-tool="select" fontSize="large" /></IconButton>
                </Tooltip>
                <Tooltip title={<Typography fontSize={12}>Transform (T)</Typography>}>
                    <IconButton color={(activeTool === 'move') ? "primary" : "inherit"} onClick={setTool} data-tool="move" aria-label="Move" size="large" style={Styles.button}><OpenWithIcon data-tool="move" fontSize="large" /></IconButton>
                </Tooltip>
                <Tooltip title={<Typography fontSize={12}>Scale (S)</Typography>}>
                    <IconButton color={(activeTool === 'resize') ? "primary" : "inherit"} onClick={setTool} data-tool="resize" aria-label="Resize" size="large" style={Styles.button}><OpenInNewIcon data-tool="resize" fontSize="large" /></IconButton>
                </Tooltip>
                <Tooltip title={<Typography fontSize={12}>Rotate (R)</Typography>}>
                    <IconButton color={(activeTool === 'rotate') ? "primary" : "inherit"} onClick={setTool} data-tool="rotate" aria-label="Rotate" size="large" style={Styles.button}><SyncIcon data-tool="rotate" fontSize="large" /></IconButton>
                </Tooltip>
                <Tooltip title={<Typography fontSize={12}>Cut (C)</Typography>}>
                    <IconButton color={(activeTool === 'cut') ? "primary" : "inherit"} onClick={setTool} data-tool="cut" aria-label="Cut" size="large" style={Styles.button}><CutIcon data-tool="cut" fontSize="large" /></IconButton>
                </Tooltip>
                <Tooltip title={<Typography fontSize={12}>Split</Typography>}>
                    <IconButton color={(activeTool === 'split') ? "primary" : "inherit"} onClick={setTool} data-tool="split" aria-label="Split" size="large" style={Styles.button}><ZoomOutMapIcon data-tool="split" fontSize="large" /></IconButton>
                </Tooltip>
                <Tooltip title={<Typography fontSize={12}>Infill</Typography>}>
                    <IconButton color={(activeTool === 'cut') ? "primary" : "inherit"} onClick={setTool} data-tool="infill" aria-label="Infill" size="large" style={Styles.button}><TextureIcon data-tool="infill" fontSize="large" /></IconButton>
                </Tooltip>
                <Tooltip title={<Typography fontSize={12}>Settings</Typography>}>
                    <IconButton color={(activeTool === 'settings') ? "primary" : "inherit"} onClick={setTool} data-tool="settings" aria-label="Settings" size="large" style={Styles.button}><SettingsIcon data-tool="settings" fontSize="large" /></IconButton>
                </Tooltip>

            </div >
        </div >
    )
}

export default Views
