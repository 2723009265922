import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function ContextMenu({ contextMenu, onClose }) {


    const handleClose = () => {
        onClose();
    };

    return (
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
            }
        >
            <MenuItem onClick={handleClose}>Copy</MenuItem>
            <MenuItem onClick={handleClose}>Print</MenuItem>
            <MenuItem onClick={handleClose}>Highlight</MenuItem>
            <MenuItem onClick={handleClose}>Email</MenuItem>
        </Menu>
    );
}
