/** @format */

import React from 'react';
import settingsFile from './fdmprinter.def.json';
import curaOpts from './curaOptions.json';

const SETTING_LEVEL = {
  beginner: 0,
  advanced: 1,
  expert: 2,
};
Object.freeze(SETTING_LEVEL);

class CustomSetting {
  hasChildren;
  isChild;
  level;
  parent;
  settingObj;

  constructor(rawSetting, opts) {
    this.hasChildren = opts.hasChildren ?? false;
    this.isChild = opts.isChild ?? false;
    this.level = opts.level ?? SETTING_LEVEL.expert;
    this.parent = opts.parent ?? false;
    this.settingObj = rawSetting;
  }
}

class PrinterService {
  #allSettings = {};
  #beginnerSettings = [];
  #advancedSettings = [];
  #expertSettings = [];
  #categories = [];
  #beginnerKeys;
  #advancedKeys;
  #expertKeys;
  #beginnerOptions;
  #advancedOptions;
  #expertOptions;
  #printerSettings;

  constructor(settings) {
    this.addChildSettings = this.addChildSettings.bind(this);
    this.init = this.init.bind(this);
    this.usedSettingsCount = this.usedSettingsCount.bind(this);
    this.#printerSettings = settings || settingsFile.settings;
    const curaOptions = curaOpts.settings;

    this.#beginnerOptions = curaOptions.beginner;
    this.#advancedOptions = curaOptions.advanced;
    this.#expertOptions = curaOptions.expert;
  }

  init() {
    // Looping through the 'settings' property of
    // the object received from the printer
    Object.entries(this.#printerSettings).forEach(
      ([categoryName, categoryObj]) => {
        // this.#categories.push(categoryName);
        this.#categories.push({ key: categoryName, label: categoryObj.label });
        // Filtering categories not included in curaOptions.json
        if (Object.keys(this.#expertOptions).includes(categoryName)) {
          // Looping through each individual setting
          this.addChildSettings(categoryObj, categoryName);
        }
      }
    );
    this.#beginnerKeys = this.flattenOptions(this.#beginnerOptions);
    this.#advancedKeys = this.flattenOptions(this.#advancedOptions);
    this.#expertKeys = this.flattenOptions(this.#expertOptions);

    for (const settingKey in this.#allSettings) {
      const customSetting = this.#allSettings[settingKey];
      switch (customSetting.level) {
        case SETTING_LEVEL.beginner:
          this.#beginnerSettings.push(customSetting);
          break;
        case SETTING_LEVEL.advanced:
          this.#advancedSettings.push(customSetting);
          break;
        case SETTING_LEVEL.expert:
          this.#expertSettings.push(customSetting);
          break;
          default:
            break;
      }
    }
  }

  flattenOptions(optionsObj) {
    let returnArray = [];
    for (const category in optionsObj) {
      const settingKeys = optionsObj[category];
      settingKeys.forEach((key) => {
        returnArray.push(key);
      });
    }
    return returnArray;
  }

  getUsedSettingKeys() {
    let returnArray = [];
    for (const key in this.#expertOptions) {
      const category = this.#expertOptions[key];
      category.forEach((settingKey) => {
        returnArray.push(settingKey);
      });
    }
    return returnArray;
  }

  usedSettingsCount() {
    let count = 0;
    for (const key in this.#expertOptions) {
      count += this.#expertOptions[key].length;
    }
    return count;
  }

  addChildSettings(parentSetting, categoryName) {
    let lowestLevel;
    Object.entries(parentSetting.children).forEach(
      ([settingKey, settingObj]) => {
        // Filtering unused settings
        if (this.#expertOptions[categoryName].includes(settingKey)) {
          lowestLevel = SETTING_LEVEL.expert;

          if (
            this.#beginnerOptions[categoryName]?.includes(settingKey) ??
            false
          ) {
            lowestLevel = SETTING_LEVEL.beginner;
          } else if (
            this.#advancedOptions[categoryName]?.includes(settingKey) ??
            false
          ) {
            lowestLevel = SETTING_LEVEL.advanced;
          }

          let hasChildren = settingObj.hasOwnProperty('children');
          let isChild = parentSetting.type !== 'category';

          let customSetting = new CustomSetting(settingObj, {
            hasChildren: hasChildren,
            isChild: isChild,
            level: lowestLevel,
            parent: isChild ? parentSetting : null,
          });

          this.#allSettings[settingKey] = customSetting;
          if (hasChildren) {
            this.addChildSettings(settingObj, categoryName);
          } else {
          }
        }
      }
    );
  }

  // dev only
  getAllSettings() {
    return this.#allSettings;
  }

  get beginnerOptions() {
    return this.#beginnerOptions;
  }

  get advancedOptions() {
    return this.#advancedOptions;
  }

  get expertOptions() {
    return this.#expertOptions;
  }

  get beginnerKeys() {
    return this.#beginnerKeys;
  }

  get advancedKeys() {
    return this.#advancedKeys;
  }

  get expertKeys() {
    return this.#expertKeys;
  }

  get beginnerSettings() {
    return this.#beginnerSettings;
  }

  get advancedSettings() {
    return this.#advancedSettings;
  }

  get expertSettings() {
    return this.#expertSettings;
  }

  get categories() {
    return this.#categories;
  }
}

export default PrinterService;
export { SETTING_LEVEL };
