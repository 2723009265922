import React, { useEffect, useRef, useState } from 'react'
import { useLoader } from '@react-three/fiber';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { ObjectLoader } from "three";
import * as THREE from 'three';



export default function Printbed(props) {
    const { printerShape } = props;
    const groupRef = useRef();

    return <group ref={groupRef}>
        <Logo {...props} />
        {printerShape === 'elliptic' ?
            <CircularBed {...props} />
            :
            <RetangularBed {...props} />}
    </group>
}

const RetangularBed = (props) => {
    const { printerVolume = [220, 220, 250] } = props;
    let url = `/models/beds/bed.stl`;
    const geometry = useLoader(STLLoader, url);
    const [floor] = useLoader(THREE.TextureLoader, ['/models/matcaps/1024/050505_747474_4C4C4C_333333.png']);
    // calculate the scale factor needed to achieve the desired dimensions
    const scaleWidth = printerVolume[0] / 235;
    const scaleLength = printerVolume[1] / 235;
    const clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), .9);
    return <mesh geometry={geometry} renderOrder={1} position={[printerVolume[0] / 2, printerVolume[1] / 2, -1]} scale={[scaleWidth, scaleLength, 1]} >
        <meshMatcapMaterial matcap={floor} side={THREE.FrontSide} clippingPlanes={[clippingPlane]} />
    </mesh>
}
const CircularBed = (props) => {
    const { printerVolume = [220, 220, 250] } = props;
    let url = `/models/beds/circular_bed.stl`;
    const geometry = useLoader(STLLoader, url);
    const [floor] = useLoader(THREE.TextureLoader, ['/models/matcaps/1024/050505_747474_4C4C4C_333333.png']);
    // calculate the scale factor needed to achieve the desired dimensions
    const scaleWidth = printerVolume[0] / 235;
    const scaleLength = printerVolume[1] / 235;
    const clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), .9);
    return <mesh geometry={geometry} renderOrder={1} position={[printerVolume[0] / 2, printerVolume[1] / 2, -1]} scale={[scaleWidth, scaleLength, 1]} >
        <meshMatcapMaterial matcap={floor} side={THREE.FrontSide} clippingPlanes={[clippingPlane]} />
    </mesh>
}

const Logo = ({ printerVolume, printerShape }) => {
    const [x, y] = printerVolume;
    let posY = 10;
    if (printerShape === 'elliptic') {
        posY = y / 2 - 15;
    }
    // Use the useLoader hook to load the JSON file
    const object = useLoader(ObjectLoader, "/models/logo/logo.json");
    return object ? <primitive object={object} renderOrder={2} position={[x - 25, posY, .1]} scale={[.15, .15, .15]} /> : null;
};
