import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SettingsComps } from '@sunsh1n3/slicer-comps';
import styled from '@emotion/styled';



const FormControlWrapper = styled(FormControlLabel, {
    label: 'form-control-wrap',
})({
    margin: 0
});

const ChkBoxWrapper = styled('div', { label: 'checkboxwrapper' })({
    display: 'flex', justifyContent: 'space-between', height: 48.9167, margin: '5px 0 5px 0'
});

export default function FormDialog(props) {
    const [tab, setTab] = useState(0);
    const { open = false, handleClose = () => { }, printer = {}, onSave = () => { } } = props;
    const { overrides, settings } = printer;
    const { machine_settings } = settings || { machine_settings: { children: {} } };
    let machine_settings_obj = {}
    Object.keys(machine_settings.children).forEach(key => {
        machine_settings_obj[key] = machine_settings.children[key].default_value;
    });

    const [userOverrides, setUserOverrides] = useState({});

    const changeHandler = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setUserOverrides(prevState => {
            let tmp = { ...prevState };
            tmp[id] = value;
            return tmp;
        });
    }

    const changeHandlerCheckbox = (e) => {
        const id = e.target.id;
        const value = e.target.checked;
        setUserOverrides(prevState => {
            let tmp = { ...prevState };
            tmp[id] = value;
            return tmp;
        });
    }

    const saveAndClose = () => {
        onSave(userOverrides);
        handleClose();
    }
    const handleChange = (event, newValue) => {
        setTab(newValue)
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
            {/* <DialogTitle>Machine Settings</DialogTitle> */}
            {printer.hasOwnProperty('overrides') &&
                <DialogContent>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Machine Settings" />
                                <Tab label="Extruder 1" />
                                <Tab label="Extruder 2" />
                            </Tabs>
                        </Box>
                    </Box>
                    <div
                        role="tabpanel"
                        hidden={tab !== 0}
                        id={`simple-tabpanel-0`}
                        aria-labelledby={`simple-tab-0`}>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="machine_width"
                                    label="X (width)"
                                    type="text"
                                    onChange={changeHandler}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                    }}
                                    defaultValue={overrides.machine_width?.default_value || machine_settings_obj.machine_width}
                                    variant="standard"
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="machine_depth"
                                    label="Y (depth)"
                                    type="text"
                                    fullWidth
                                    onChange={changeHandler}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                    }} defaultValue={overrides.machine_depth?.default_value || machine_settings_obj.machine_depth}
                                    variant="standard"
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="machine_height"
                                    label="Z (height)"
                                    type="text"
                                    fullWidth
                                    onChange={changeHandler}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                    }}
                                    defaultValue={overrides.machine_height?.default_value || machine_settings_obj.machine_height}
                                    variant="standard"
                                />
                                <TextField
                                    id="machine_shape"
                                    select
                                    onChange={changeHandler}
                                    label="Build plate shape"
                                    defaultValue={overrides.machine_shape?.default_value || machine_settings_obj.machine_shape}
                                    variant="standard"
                                    fullWidth
                                    sx={{ margin: '8px 0px 6px 0px' }}
                                    helperText=""
                                >
                                    <MenuItem value="rectangular">
                                        Rectangular
                                    </MenuItem>
                                    <MenuItem value="elliptic">
                                        Eliptical
                                    </MenuItem>
                                </TextField>
                                <ChkBoxWrapper>
                                    <FormControlWrapper labelPlacement="start" control={<Checkbox onChange={changeHandlerCheckbox} id="machine_center_is_zero" defaultChecked={overrides.machine_center_is_zero?.default_value || machine_settings_obj.machine_center_is_zero} size="large" />} label="Origin at center" />
                                    <FormControlWrapper labelPlacement="start" control={<Checkbox onChange={changeHandlerCheckbox} id="machine_heated_bed" defaultChecked={overrides.machine_heated_bed?.default_value} size="large" />} label="Heated Bed" />
                                    <FormControlWrapper labelPlacement="start" control={<Checkbox onChange={changeHandlerCheckbox} id="machine_heated_build_volume" defaultChecked={overrides.machine_heated_build_volume?.default_value || machine_settings_obj.machine_heated_build_volume} size="large" />} label="Heated build volume" />
                                </ChkBoxWrapper>
                                <TextField
                                    id="machine_gcode_flavor"
                                    select
                                    onChange={changeHandler}
                                    label="g code flavor"
                                    defaultValue={overrides.machine_gcode_flavor?.default_value || machine_settings_obj.machine_gcode_flavor}
                                    variant="standard"
                                    fullWidth
                                    sx={{ margin: '8px 0px 6px 0px' }}
                                    helperText=""
                                >
                                    {Object.keys(machine_settings.children.machine_gcode_flavor?.options || {}).map(key => {
                                        return <MenuItem key={key} value={key}>
                                            {machine_settings.children.machine_gcode_flavor?.options[key]}
                                        </MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="machine_head_with_fans_polygon"
                                    label="X Min"
                                    type="text"
                                    fullWidth
                                    onChange={changeHandler}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                    }}
                                    defaultValue={overrides.machine_head_with_fans_polygon?.default_value[1][0] || machine_settings_obj.machine_head_with_fans_polygon[1][0]}
                                    variant="standard"
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="machine_head_with_fans_polygon"
                                    label="Y Min"
                                    type="text"
                                    fullWidth
                                    onChange={changeHandler}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                    }}
                                    defaultValue={overrides.machine_head_with_fans_polygon?.default_value[1][1] || machine_settings_obj.machine_head_with_fans_polygon[1][1]}
                                    variant="standard"
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="machine_head_with_fans_polygon"
                                    label="X Max"
                                    type="text"
                                    fullWidth
                                    onChange={changeHandler}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                    }}
                                    defaultValue={overrides.machine_head_with_fans_polygon?.default_value[3][0] || machine_settings_obj.machine_head_with_fans_polygon[3][0]}
                                    variant="standard"
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="machine_head_with_fans_polygon"
                                    label="Y Max"
                                    type="text"
                                    fullWidth
                                    onChange={changeHandler}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                    }}
                                    defaultValue={overrides.machine_head_with_fans_polygon?.default_value[3][1] || machine_settings_obj.machine_head_with_fans_polygon[3][1]}
                                    variant="standard"
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="gantry_height"
                                    label="Gantry Height"
                                    type="text"
                                    fullWidth
                                    onChange={changeHandler}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                    }}
                                    defaultValue={(overrides.machine_shape?.default_value === 'elliptic' ? overrides.machine_depth?.default_value || machine_settings_obj.gantry_height : overrides.gantry_height?.value || machine_settings_obj.gantry_height)}
                                    variant="standard"
                                />
                                <TextField
                                    id="machine_extruder_count"
                                    select
                                    label="Number of Extruders"
                                    defaultValue={overrides.machine_extruder_count?.default_value || machine_settings_obj.machine_extruder_count}
                                    variant="standard"
                                    fullWidth
                                    onChange={changeHandler}
                                    sx={{ margin: '8px 0px 6px 0px' }}
                                    helperText=""
                                >
                                    {Array.from({ length: 16 }, (_, i) => i + 1).map(num => {
                                        return <MenuItem key={num} value={num}>
                                            {num}
                                        </MenuItem>
                                    })}
                                </TextField>
                                <ChkBoxWrapper>
                                    <FormControlWrapper labelPlacement="start" control={<Checkbox onChange={changeHandlerCheckbox} defaultChecked={overrides.machine_use_extruder_offset_to_offset_coords?.default_value || machine_settings_obj.machine_use_extruder_offset_to_offset_coords} size="large" />} label="Apply Extruder offsets to gcode" />
                                </ChkBoxWrapper>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="machine_start_gcode"
                                    label="Start g-code"
                                    type="text"
                                    multiline={true}
                                    onChange={changeHandler}
                                    fullWidth
                                    maxRows={10}
                                    defaultValue={overrides.machine_start_gcode?.default_value || machine_settings_obj.machine_start_gcode}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="machine_end_gcode"
                                    label="End g-code"
                                    type="text"
                                    multiline={true}
                                    maxRows={10}
                                    onChange={changeHandler}
                                    fullWidth
                                    defaultValue={overrides.machine_end_gcode.default_value || machine_settings_obj.machine_end_gcode}
                                    variant="standard"
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div
                        role="tabpanel"
                        hidden={tab !== 1}
                        id={`simple-tabpanel-1`}
                        aria-labelledby={`simple-tab-1`}>
                    </div>
                </DialogContent>}
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={saveAndClose}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}