/** @format */

import React, {useState} from 'react'
import Emitter from '../../utils/events';
import Tooltip from '@mui/material/Tooltip';
import Typography from  '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const boxSize = 49.5;
const position = 9.9; // boxSize * .2

const Styles = {
  root: {
    width: 140,
    height: 200,
    position: 'absolute',
    right: 45,
    zIndex:100,
    bottom:150,
    alignSelf: 'center',
  },
  diamond: {
    width: boxSize,
    height: boxSize,
    transform: 'rotateY(0deg) rotate(45deg)',
    border: `${position}px solid #CAC9CA`,
    boxSizing: 'border-box',
    position: 'absolute',
    cursor: 'pointer',
  },
  active: {
    borderColor: '#174CFF',
  }
};

const StyledTooltip = (props) => {
  const {title} = props;
  return  <Tooltip {...props} title={<Typography fontSize={'1.5rem'}>{title}</Typography>}></Tooltip>
}
const Views = ({onClose}) => {
  const [activeView, setActiveView] = useState('iso')

  const setView = e => {
    const view = e.target.dataset.view
    if (view) {
      setActiveView(view);
      Emitter.emit('setView',view);
    }
  }

  const rotate = e => {
    const direction = e.target.dataset.view
    Emitter.emit('rotate',direction);
  }
  
  return (
    <div style={Styles.root} onClick={onClose}>
      <StyledTooltip title="Iso">
        <div
          data-view="iso"
          onClick={setView}
          style={{...Styles.diamond,left: 35, borderColor:activeView === 'iso' ? '#174CFF' : '#CAC9CA'}}>
        </div>
      </StyledTooltip>
      <StyledTooltip title="Rotate Left">
        <div
          data-view="left"
          onClick={rotate}
          style={{...Styles.diamond,top: 36, left: 0, borderColor:activeView === 'leftiso' ? '#174CFF' : '#CAC9CA'}}>
        </div>
      </StyledTooltip>
      <StyledTooltip title="Rotate Right">
        <div
          data-view="right"
          onClick={rotate}
          style={{...Styles.diamond,top: 36, left: 70, borderColor:activeView === 'rightiso' ? '#174CFF' : '#CAC9CA'}}>
        </div>
      </StyledTooltip>
      <StyledTooltip title="Top">
        <div
          data-view="top"
          onClick={setView}
          style={{...Styles.diamond,top: 72, left: 35, borderColor:activeView === 'top' ? '#174CFF' : '#CAC9CA'}}>
        </div>
      </StyledTooltip>
      <StyledTooltip title="Left">
        <div
          data-view="left"
          onClick={setView}
          style={{...Styles.diamond,top: 108, left: 0, borderColor:activeView === 'left' ? '#174CFF' : '#CAC9CA'}}>
        </div>
      </StyledTooltip>
      <StyledTooltip title="Right">
        <div
          data-view="right"
          onClick={setView}
          style={{...Styles.diamond,top: 108, left: 70, borderColor:activeView === 'right' ? '#174CFF' : '#CAC9CA'}}>
        </div>
      </StyledTooltip>
      <StyledTooltip title="Bottom">
        <div
          data-view="bottom"
          onClick={setView}
          style={{...Styles.diamond,top: 144, left: 35, borderColor:activeView === 'bottom' ? '#174CFF' : '#CAC9CA'}}>
        </div>
      </StyledTooltip>
      <div style={{fontSize: '1.5em', position: 'absolute',bottom: -40, width:'100%',textAlign:'center',paddingRight:15}}>View: <strong>{activeView.toTitleCase()}</strong></div>
    </div>
  )
}

export default Views
