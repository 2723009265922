import React, { useEffect, useState } from 'react';
// Custom components
import { LabeledCheckbox, MenuIcon, TopBarInput } from '@sunsh1n3/slicer-comps';
// import { TopBarInput } from '@sunsh1n3/slicer-comps/settingsComps';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Feather icons
import { Sliders } from 'react-feather';
// Material UI
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import { styled } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';


import { localOverides } from '../../utils/common';

import MachineSettings from '../machineSettings';
//#region Styled components
const StyledDivider = styled(Divider, { label: 'styled-divider' })({
  color: '#e9eaee',
});

const Spacer = styled('div', { label: 'spacer' })({
  flexGrow: '1',
  height: '0',
  margin: '0',
  padding: '0',
});

const StyledAutocomplete = styled(Autocomplete, { label: 'styled-autocompolete' })({
  width: 300,
  '& fieldset': {
    border: 0
  }
});

const Wrapper = styled('div', { label: 'top-bar-wrap' })({
  alignItems: 'center',
  backgroundColor: '#f6f6f6',
  display: 'flex',
  flexDirection: 'row',
  height: '7.5rem',
  justifyContent: 'flex-start',
  margin: 1,
  padding: '0',
  width: '100%',
});
//#endregion

function CustomDivider() {
  return <StyledDivider flexItem={true} orientation='vertical' />;
}
const defaultPrinter = JSON.parse(localStorage.getItem('printer')) || { "label": "Creality Ender-3 Pro", "id": "64469c4d9c174fa536a3d5cc" };
const defaultMaterial = JSON.parse(localStorage.getItem('material')) || { "label": "Generic PLA Generic", "id": "644c0c8c162ddb54b83efa46" };




export default function SlicerTopBar({ onPrinterChange, onMaterialChange, onClickSettings, overrides, onSettingsChange = () => { } }) {
  const [printers, setPrinters] = useState([]);
  const [printer, setPrinter] = useState({});
  const [materials, setMaterials] = useState([]);
  const [value, setValue] = React.useState({ label: '', id: '' });
  const [openMachineSettings, setOpenMachineSettings] = useState(false);
  const [materialValue, setMaterialValue] = React.useState({ label: '', id: '' });
  const [layerHeight, setLayerHeight] = useState(overrides?.layer_height || localOverides["layer_height"] || '0.15');
  const [infill, setInfill] = useState(overrides?.infill_sparse_density || localOverides["infill_sparse_density"] || '20');

  const [inputValue, setInputValue] = React.useState('');
  const [materialInputValue, setMaterialInputValue] = React.useState('');
  useEffect(() => {
    getPrinters(defaultPrinter.label || '', true);
    getMaterials(defaultMaterial.label || '', true);
  }, []);

  const toggleMachineSettings = () => {
    setOpenMachineSettings(!openMachineSettings);
  }

  const getPrinters = (name, setFirst = false) => {
    let url = '/api/printerdefs/?skip=0&limit=50&sort=_system.created&dir=-1';
    if (name) {
      url += '&search=' + encodeURIComponent(JSON.stringify({ name }))
    }
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        let tmp = resp.resp.map(printer => {
          return { label: printer.name, id: printer.id };
        })
        setPrinters(tmp);
        if (setFirst === true) {
          setValue(tmp[0]);
        }
      });
  }

  const getMaterials = (name, setFirst = false) => {
    let url = '/api/materials/?skip=0&limit=50&sort=_system.created&dir=-1';
    if (name) {
      url += '&search=' + encodeURIComponent(JSON.stringify({ name }))
    }
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        let tmp = resp.resp.map(printer => {
          return { label: printer.name, id: printer.id };
        })
        setMaterials(tmp);
        if (setFirst === true) {
          setMaterialValue(tmp[0]);
        }
      });
  }

  useEffect(() => {
    if (value && value.id) {
      let url = '/api/printerdef/' + value.id;
      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        return res.json();
      }).then(resp => {
        setPrinter(resp);
      }).catch(e => {
        console.error(e);
      });
    }
  }, [value]);

  useEffect(() => {
    if (onPrinterChange) {
      onPrinterChange(printer)
    }
  }, [printer]);

  useEffect(() => {
    if (overrides) {
      Object.keys(overrides).forEach(key => {
        setValues(key, overrides[key]);
      });
    }
  }, [overrides]);

  const setValues = (key, value) => {
    switch (key) {
      case 'layer_height':
        setLayerHeight(value);
        break;
      case 'infill_sparse_density':
        setInfill(value);
        break;
      default:
        break;
    }
  }

  const settingsChange = setting => (value) => {
    if (setting === 'adhesion_type') {
      value = (value === true) ? "skirt" : "none";
    }
    setValues(setting, value);
    onSettingsChange(setting, value);
  }

  const updatePrinterSettings = (settings) => {
    Object.keys(settings).forEach(key => {
      onSettingsChange(key, settings[key]);
    });
  }

  return (
    <Wrapper>
      <Spacer
        sx={{
          maxWidth: '7.3vw',
        }}
      />
      <CustomDivider />
      <StyledAutocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          localStorage.setItem('printer', JSON.stringify(newValue));
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          getPrinters(newInputValue);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        id="Printer"
        options={printers}
        // sx={{ width: 300, '&fieldset': { border: 'none' } }}
        renderInput={(params) => <TextField {...params} />}
      />
      <CustomDivider />
      <IconButton aria-label="Machine Settings" onClick={toggleMachineSettings}>
        <SettingsIcon />
      </IconButton>
      <MachineSettings printer={printer} onSave={updatePrinterSettings} open={openMachineSettings} handleClose={toggleMachineSettings} />
      <CustomDivider />
      <StyledAutocomplete
        value={materialValue}
        onChange={(event, newValue) => {
          setMaterialValue(newValue);
          localStorage.setItem('material', JSON.stringify(newValue));
        }}
        inputValue={materialInputValue}
        onInputChange={(event, newInputValue) => {
          setMaterialInputValue(newInputValue);
          getMaterials(newInputValue);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        id="Material"
        options={materials}
        sx={{ width: 300, border: 'none' }}
        renderInput={(params) => <TextField {...params} />}
      />
      <CustomDivider />
      {/* <ValueLabel label='Fine' value='0.15mm' /> */}
      <TopBarInput settingLabel='Layer Height' val={layerHeight} onChange={settingsChange("layer_height")} />
      {/* <ValueLabel label='Infill' value='20%' /> */}
      <TopBarInput settingLabel='Infill' val={infill} unitLabel='%' onChange={settingsChange("infill_sparse_density")} />
      <LabeledCheckbox label='Support' defaultChecked={localOverides["support_enable"] === "True"} changeFunc={settingsChange("support_enable")} />
      <LabeledCheckbox label='Adhesion' defaultChecked={(localOverides.hasOwnProperty("adhesion_type") && localOverides["adhesion_type"] !== "none")} changeFunc={settingsChange("adhesion_type")} />
      <Spacer />
      <MenuIcon onClick={onClickSettings} icon={<Sliders color='rgb(17,17,17)' />} />
    </Wrapper>
  );
}
