import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
// Custom components
// Material UI
import ButtonBase from '@mui/material/ButtonBase';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from 'notistack';
// React Router
import { Link, useParams, useSearchParams, useNavigate } from 'react-router-dom';

//#region Styled components
const FormControlWrapper = styled(FormControl, {
  label: 'register-form-control-wrap',
})({
  alignItems: 'flex-start',
});

const FormWrapper = styled('form', { label: 'register-form-wrap' })({
  height: 'min-content',
  width: '100%',
});

const HeaderWrapper = styled('div', { label: 'register-head-wrap' })({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '4rem',
  width: '100%',
});

const StyledBtn = styled(ButtonBase, { label: 'register-btn' })({
  marginTop: '5.5rem',
  width: '100%',
  '&:hover': {
    boxShadow: '0.5rem 0.5rem 1.5rem #0f0f0f',
  },
});

const StyledInput = styled(InputBase, { label: 'register-inp' })({
  backgroundColor: '#efefef',
  border: '1px solid #ccc',
  borderRadius: '4px',
  color: '#333',
  fontSize: '1.75rem',
  height: '6.75rem',
  marginBottom: '1.25rem',
  padding: '1rem 1.5rem',
  width: '100%',
  '&.Mui-error': {
    borderColor: 'red',
  },
  '&.Mui-focused': {
    borderColor: '#1976d2',
  },
});

const StyledLabel = styled(InputLabel, { label: 'register-inp-lbl' })({
  marginBottom: '0.5rem',
  position: 'relative',
});

const StyledTxt = styled(Typography, { label: 'register-txt' })({
  color: '#333',
  '& a': {
    color: '#0062ff',
  },
});

const Wrapper = styled('div', { label: 'register-wrap' })({
  alignItems: 'center',
  backgroundAttachment: 'scroll',
  backgroundColor: 'transparent',
  backgroundImage:
    'url(/images/img-registration-multip.png),url(/images/img-registration-multip.png),linear-gradient(#a8fff5,#18c9ae);',
  backgroundPositionX: 'left -20%,right -20%,center',
  backgroundPositionY: 'center',
  backgroundRepeat: 'no-repeat, no-repeat, repeat',
  backgroundSize: '45%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: '0 35.2vw',
  position: 'relative',
  width: '100%',
});
//#endregion

const numberTest = /\d/;
const capitalTest = /[A-Z]/;
const lowercaseTest = /[a-z]/;
const specialCharTest = /\W/;
const excludedCharTest = /\S/;

export default function RegisterComp(props) {
  // const [isValidPassword, setIsValidPassword] = useState(false);
  // const { code } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [custName, setCustName] = useState('');
  const [inviteCode, setInviteCode] = useState(searchParams.get('code') ?? '');
  const [isValidForm, setIsValidForm] = useState(false);
  //#region Email input handlers
  const [custEmail, setCustEmail] = useState('');
  const emailRef = useRef(null);
  //#endregion
  //#region Password input handlers
  const [newPassword, setNewPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const passwordRef = useRef(null);
  //#endregion
  //#region Confirm password input handlers
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const confirmPasswordRef = useRef(null);
  //#endregion
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      (inviteCode === '' ||
        passwordErr ||
        confirmPasswordErr ||
        custEmail === '') &&
      isValidForm
    ) {
      setIsValidForm(false);
    } else if (!passwordErr && !confirmPasswordErr && !isValidForm) {
      setIsValidForm(true);
    }
  }, [custEmail, inviteCode, passwordErr, confirmPasswordErr]);

  function handleConfirmPasswordBlur() {
    if (newPassword && newPassword !== '' && !passwordErr) {
      let isPasswordMatch = confirmPassword === newPassword;
      if (!isPasswordMatch) {
        setConfirmPasswordErr(confirmPassword !== newPassword);
        confirmPasswordRef.current.setCustomValidity('Passwords do not match');
        confirmPasswordRef.current.checkValidity();
        confirmPasswordRef.current.reportValidity();
      } else {
        setConfirmPasswordErr(false);
      }
    } else {
      setConfirmPasswordErr(false);
    }
  }

  function handleEmailBlur() {
    console.log('emailRef.current: ', emailRef.current);
  }

  function handleFormSubmit() {
    setDisableSubmit(true);
    if (!passwordErr && !confirmPasswordErr) {
      if (newPassword === '') {
        setPasswordErr(true);
        passwordRef.current.setCustomValidity('A password is required');
        passwordRef.current.checkValidity();
        passwordRef.current.reportValidity();
      } else if (confirmPassword === '') {
        setConfirmPasswordErr(true);
        confirmPasswordRef.current.setCustomValidity(
          'Please confirm the password'
        );
        confirmPasswordRef.current.checkValidity();
        confirmPasswordRef.current.reportValidity();
      }

      fetch('/api/auth/register', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: custEmail,
          userId: custEmail,
          name: custName,
          password: newPassword,
          password2: confirmPassword,
          inviteCode: inviteCode,
        }),
      }).then(resp => {
        if (resp.ok) {
          return resp.json()
        }
        return resp.text().then(text => { throw new Error(text) })
      }).then(resp => {
        enqueueSnackbar('Check your email to confirm your account.', { variant: 'success' });
        navigate('/login')

      }).catch(async err => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
    }
    setDisableSubmit(false);
  }

  function validatePassword() {
    const currentPassVal = passwordRef.current.value;
    if (currentPassVal && currentPassVal !== '') {
      let isValidPass = true;
      let tempValidationMsg = '';
      if (currentPassVal.length < 8) {
        tempValidationMsg = 'Password must contain at least 8 characters';
        // tempValidationMsg += 'At least 8 characters\n ';
        isValidPass = false;
      }
      // if (!numberTest.test(currentPassVal)) {
      //   tempValidationMsg += 'A number\n ';
      //   isValidPass = false;
      // }
      // if (!capitalTest.test(currentPassVal)) {
      //   tempValidationMsg += 'A capital letter\n ';
      //   isValidPass = false;
      // }
      // if (!specialCharTest.test(currentPassVal)) {
      //   tempValidationMsg += 'A special character\n ';
      //   isValidPass = false;
      // }
      setPasswordErr(!isValidPass);
      passwordRef.current.setCustomValidity(tempValidationMsg);
      passwordRef.current.checkValidity();
      passwordRef.current.reportValidity();
      handleConfirmPasswordBlur();
    }
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <StyledTxt variant='h2'>Create account</StyledTxt>
        <StyledTxt variant='h3'>
          Already a user? <Link to={'/login'}>Sign in</Link>
        </StyledTxt>
      </HeaderWrapper>
      <FormWrapper>
        <FormControlWrapper fullWidth required={false} variant='outlined'>
          <StyledLabel disableAnimation={true} shrink={true} variant='outlined'>
            Full Name
          </StyledLabel>
          <StyledInput
            id='inp-name'
            name='inp-name'
            onChange={(e) => setCustName(e.target.value)}
            type='text'
          />
        </FormControlWrapper>
        <FormControlWrapper
          error={
            !emailRef.current?.validity.valid &&
            !emailRef.current?.validity.valueMissing
          }
          fullWidth
          required={true}
          variant='outlined'
        >
          <StyledLabel disableAnimation={true} shrink={true} variant='outlined'>
            Email Address
          </StyledLabel>
          <StyledInput
            id='inp-email'
            inputRef={emailRef}
            name='inp-email'
            onBlur={() => handleEmailBlur()}
            onChange={(e) => setCustEmail(e.target.value)}
            type='email'
          />
        </FormControlWrapper>
        <FormControlWrapper
          error={
            !passwordRef.current?.validity.valid &&
            !passwordRef.current?.validity.valueMissing
          }
          fullWidth
          required={true}
          variant='outlined'
        >
          <StyledLabel disableAnimation={true} shrink={true} variant='outlined'>
            Password
          </StyledLabel>
          <StyledInput
            autoComplete='new-password'
            endAdornment={
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            }
            id='inp-pass'
            inputRef={passwordRef}
            name='inp-pass'
            onBlur={() => validatePassword()}
            onChange={(event) => setNewPassword(event.target.value)}
            required={true}
            type={showPassword ? 'text' : 'password'}
          />
        </FormControlWrapper>
        <FormControlWrapper
          error={confirmPasswordErr}
          fullWidth
          required={true}
          variant='outlined'
        >
          <StyledLabel disableAnimation={true} shrink={true} variant='outlined'>
            Confirm Password
          </StyledLabel>
          <StyledInput
            endAdornment={
              <IconButton
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            }
            id='inp-confirm'
            inputRef={confirmPasswordRef}
            name='inp-confirm'
            onBlur={() => handleConfirmPasswordBlur()}
            onChange={(event) => setConfirmPassword(event.target.value)}
            type={showConfirmPassword ? 'text' : 'password'}
          />
        </FormControlWrapper>
        <FormControlWrapper fullWidth required={true} variant='outlined'>
          <StyledLabel disableAnimation={true} shrink={true} variant='outlined'>
            Invite Code
          </StyledLabel>
          <StyledInput
            id='inp-invite-code'
            name='inp-invite-code'
            onChange={(event) => setInviteCode(event.target.value)}
            type='text'
            value={inviteCode}
          />
        </FormControlWrapper>
        <StyledBtn
          disabled={disableSubmit}
          onClick={() => handleFormSubmit()}
          variant='filled'
        >
          Sign Up
        </StyledBtn>
      </FormWrapper>
    </Wrapper>
  );
}
