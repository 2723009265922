/** @format */

import React, { useState } from 'react'
import styled from '@emotion/styled';
import Draggable from 'react-draggable';


import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { SliderObj, SliderComp } from '@sunsh1n3/slicer-comps';

const SLIDERS = [
    new SliderObj({ isParent: true, sliderLbl: 'S-X', unitLbl: 'mm' }),
    new SliderObj({ isParent: false, sliderLbl: 'S-X', unitLbl: 'mm' }),
    new SliderObj({ isParent: true, sliderLbl: 'S-Y', unitLbl: 'mm' }),
    new SliderObj({ isParent: true, sliderLbl: 'S-Z', unitLbl: 'mm' }),
];

const Styles = {
    root: {
        position: 'absolute',
        display: 'flex',
        top: 40,
        right: 40,
        maxHeight: "75vh",
        overflow: "scroll",
        zIndex: 100,
        flexDirection: 'column'
    },
    header: {
        width: '100%',
        backgroundColor: 'black',
        color: 'white',
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
        fontSize: '2rem',
        height: '8rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4rem',

    },
    footer: {
        width: '100%',
        height: '1.5rem',
        backgroundColor: '#d8d8d8',
        borderBottomLeftRadius: 25,
        borderBottomRightRadius: 25,
    },
    divider: {
        width: '100%',
        // height: '4rem',
        display: 'flex',
        fontSize: '2rem',
        backgroundColor: '#d8d8d8',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem 2rem',
    },
    paper: {
        padding: 10,
        fontSize: '5 rem',
        marginBottom: '2rem',
        position: 'relative'
    },
    // button: {
    //     margin: 'auto'
    // },
    tools: {
        width: 300,
        // height: '100%',
        // backgroundColor: 'black',
        // borderRadius: 28,
        // display: 'flex',
        alignSelf: 'center',
        margin: 'auto',
        color: 'white',
        zIndex: 100,
    },
    button: {
        color: 'white',
        margin: '.25em .5em',
    },
    toolTip: {
        fontSize: '2em'
    },
    active: {
        borderColor: '#174CFF',
    },
    slider: {
    }
};

const StyledBtn = styled(Button, { label: 'fit-btn' })({
    color: 'white',
    fontSize: '1.6rem',
    borderColor: 'white'
});

let colorsArray = [
    "63b598", "ce7d78", "ea9e70", "a48a9e", "c6e1e8", "648177", "0d5ac1",
    "f205e6", "1c0365", "14a9ad", "4ca2f9", "a4e43f", "d298e2", "6119d0",
    "d2737d", "c0a43c", "f2510e", "651be6", "79806e", "61da5e", "cd2f00",
    "9348af", "01ac53", "c5a4fb", "996635", "b11573", "4bb473", "75d89e",
    "2f3f94", "2f7b99", "da967d", "34891f", "b0d87b", "ca4751", "7e50a8",
    "c4d647", "e0eeb8", "11dec1", "289812", "566ca0", "ffdbe1", "2f1179",
    "935b6d", "916988", "513d98", "aead3a", "9e6d71", "4b5bdc", "0cd36d",
    "250662", "cb5bea", "228916", "ac3e1b", "df514a", "539397", "880977",
    "f697c1", "ba96ce", "679c9d", "c6c42c", "5d2c52", "48b41b", "e1cf3b",
    "5be4f0", "57c4d8", "a4d17a", "225b8", "be608b", "96b00c", "088baf",
    "f158bf", "e145ba", "ee91e3", "05d371", "5426e0", "4834d0", "802234",
    "6749e8", "0971f0", "8fb413", "b2b4f0", "c3c89d", "c9a941", "41d158",
    "fb21a3", "51aed9", "5bb32d", "807fb", "21538e", "89d534", "d36647",
    "7fb411", "0023b8", "3b8c2a", "986b53", "f50422", "983f7a", "ea24a3",
    "79352c", "521250", "c79ed2", "d6dd92", "e33e52", "b2be57", "fa06ec",
    "1bb699", "6b2e5f", "64820f", "1c271", "21538e", "89d534", "d36647",
    "7fb411", "0023b8", "3b8c2a", "986b53", "f50422", "983f7a", "ea24a3",
    "79352c", "521250", "c79ed2", "d6dd92", "e33e52", "b2be57", "fa06ec",
    "1bb699", "6b2e5f", "64820f", "1c271", "9cb64a", "996c48", "9ab9b7",
    "06e052", "e3a481", "0eb621", "fc458e", "b2db15", "aa226d", "792ed8",
    "73872a", "520d3a", "cefcb8", "a5b3d9", "7d1d85", "c4fd57", "f1ae16",
    "8fe22a", "ef6e3c", "243eeb", "1dc18", "dd93fd", "3f8473", "e7dbce",
    "421f79", "7a3d93", "635f6d", "93f2d7", "9b5c2a", "15b9ee", "0f5997",
    "409188", "911e20", "1350ce", "10e5b1", "fff4d7", "cb2582", "ce00be",
    "32d5d6", "17232", "608572", "c79bc2", "00f87c", "77772a", "6995ba",
    "fc6b57", "f07815", "8fd883", "060e27", "96e591", "21d52e", "d00043",
    "b47162", "1ec227", "4f0f6f", "1d1d58", "947002", "bde052", "e08c56",
    "28fcfd", "bb09b", "36486a", "d02e29", "1ae6db", "3e464c", "a84a8f",
    "911e7e", "3f16d9", "0f525f", "ac7c0a", "b4c086", "c9d730", "30cc49",
    "3d6751", "fb4c03", "640fc1", "62c03e", "d3493a", "88aa0b", "406df9",
    "615af0", "4be47", "2a3434", "4a543f", "79bca0", "a8b8d4", "00efd4",
    "7ad236", "7260d8", "1deaa7", "06f43a", "823c59", "e3d94c", "dc1c06",
    "f53b2a", "b46238", "2dfff6", "a82b89", "1a8011", "436a9f", "1a806a",
    "4cf09d", "c188a2", "67eb4b", "b308d3", "fc7e41", "af3101", "ff065",
    "71b1f4", "a2f8a5", "e23dd0", "d3486d", "00f7f9", "474893", "3cec35",
    "1c65cb", "5d1d0c", "2d7d2a", "ff3420", "5cdd87", "a259a4", "e4ac44",
    "1bede6", "8798a4", "d7790f", "b2c24f", "de73c2", "d70a9c", "25b67",
    "88e9b8", "c2b0e2", "86e98f", "ae90e2", "1a806b", "436a9e", "0ec0ff",
    "f812b3", "b17fc9", "8d6c2f", "d3277a", "2ca1ae", "9685eb", "8a96c6",
    "dba2e6", "76fc1b", "608fa4", "20f6ba", "07d7f6", "dce77a", "77ecca"]

const Cut = ({ onChange = () => { }, onApply = () => { }, modelVolume = [0, 0, 0], printerVolume = [0, 0, 0], printerName = '' }) => {
    let color = colorsArray[Math.floor(Math.random() * colorsArray.length)];
    const [values, setValues] = useState([{ pos: 0, coord: 'x', color }]);
    const [Xvalues, setXValues] = useState([{ pos: 0, coord: 'x', color }]);
    const [Yvalues, setYValues] = useState([]);
    const [Zvalues, setZValues] = useState([]);
    const [maxVolume, setMaxVolume] = useState(printerVolume.map(n => n - 10));
    const apply = () => {
        onApply(values);
    }
    const handleChange = index => (v) => {
        console.log(index, v)
        // let n = exponentialFactor(newValue);
        // let type = event.target.name;
        let tmp = [...values];
        // if (type === 'coord') {
        //     tmp[index]['pos'] = 0;
        // }
        tmp[index]['pos'] = v;
        setValues(tmp);
    };

    useEffect(() => {
        setMaxVolume(printerVolume.map(n => n - 10));
    }, [printerVolume])

    useEffect(() => {
        onChange(values);
    }, [values]);

    const handleMaxVolume = (e) => {
        let value = Number(e.target.value);
        let index = Number(e.target.dataset.index);
        if (value > 0 && value <= printerVolume[index]) {
            let tmp = [...maxVolume];
            tmp[index] = value;
            setMaxVolume(tmp);
        }
    }

    const deleteCut = index => () => {
        let tmp = [...values];
        tmp.splice(index, 1);
        setValues(tmp);
    }

    const addCut = coord => () => {
        let tmp = [...values];
        let color = colorsArray[Math.floor(Math.random() * colorsArray.length)];
        tmp.push({ pos: 0, coord, color });
        setValues(tmp);
    }

    const printerFit = () => {
        let [maxX, maxY, maxZ] = maxVolume;
        const [x, y, z] = modelVolume;
        let xCuts = Math.ceil(x / maxX);
        let yCuts = Math.ceil(y / maxY);
        let zCuts = Math.ceil(z / maxZ);
        let fitCuts = [];
        let color = colorsArray[Math.floor(Math.random() * colorsArray.length)];
        for (let i = 1; i < xCuts; i++) {
            color = colorsArray[Math.floor(Math.random() * colorsArray.length)];

            fitCuts.push({ coord: 'x', pos: Math.floor(x / xCuts) * i, color })
        }
        for (let i = 1; i < yCuts; i++) {
            color = colorsArray[Math.floor(Math.random() * colorsArray.length)];

            fitCuts.push({ coord: 'y', pos: Math.floor(y / yCuts) * i, color })
        }
        for (let i = 1; i < zCuts; i++) {
            color = colorsArray[Math.floor(Math.random() * colorsArray.length)];
            fitCuts.push({ coord: 'z', pos: Math.floor(z / zCuts) * i, color })
        }
        setValues(fitCuts);
    }
    let xIndex = 0;
    let yIndex = 0;
    let zIndex = 0;
    return (
        <Draggable handle=".cut_handle">
            <div style={Styles.root} >
                <div style={Styles.header} className="cut_handle"><span>Cut Tool</span><span><StyledBtn onClick={printerFit} variant="outlined" size="small">Fit</StyledBtn> <StyledBtn onClick={apply} variant="outlined" size="small">Apply</StyledBtn></span></div>
                {/* <SliderBox sliders={SLIDERS} onAddSlider={(d) => { console.log(d) }} onSliderChange={handleChange} /> */}
                <div style={Styles.divider}>
                    <span>X  -  {modelVolume[0].toFixed(2)}mm</span>
                    <IconButton onClick={addCut('x')}><AddIcon sx={{ color: '#333' }} /></IconButton>
                </div>
                {values.map((v, index) => {
                    console.log(v, index)
                    const { pos, coord, color } = v;
                    if (coord !== 'x') {
                        return '';
                    }
                    xIndex++;
                    let sliderMax = modelVolume[0]
                    if (coord === 'y') {
                        sliderMax = modelVolume[1]
                    } else if (coord === 'z') {
                        sliderMax = modelVolume[2]
                    }
                    let silde = new SliderObj({ isParent: false, sliderLbl: xIndex, unitLbl: 'mm', initVal: pos, valMax: sliderMax, valMin: 0 })
                    return (
                        <SliderComp
                            value={pos}
                            // getAriaValueText={valuetext}
                            // valueLabelFormat={valuetext}
                            aria-labelledby="discrete-slider"
                            aria-label="cut"
                            valueLabelDisplay="auto"
                            onChangeCommit={handleChange(index)}
                            onIconClick={deleteCut(index)}
                            sliderObj={silde}
                        />)
                })}
                <div style={Styles.divider}>
                    <span>Y  -  {modelVolume[1].toFixed(2)}mm</span>
                    <IconButton onClick={addCut('y')}><AddIcon sx={{ color: '#333' }} /></IconButton>
                </div>
                {values.map((v, index) => {
                    const { pos, coord, color } = v;
                    if (coord !== 'y') {
                        return '';
                    }
                    yIndex++;
                    let sliderMax = modelVolume[0]
                    if (coord === 'y') {
                        sliderMax = modelVolume[1]
                    } else if (coord === 'z') {
                        sliderMax = modelVolume[2]
                    }
                    let silde = new SliderObj({ isParent: false, sliderLbl: yIndex, unitLbl: 'mm', initVal: pos, valMax: sliderMax, valMin: 0 })
                    return (
                        <SliderComp
                            value={pos}
                            // getAriaValueText={valuetext}
                            // valueLabelFormat={valuetext}
                            aria-labelledby="discrete-slider"
                            aria-label="cut"
                            valueLabelDisplay="auto"
                            onChangeCommit={handleChange(index)}
                            onIconClick={deleteCut(index)}
                            sliderObj={silde}
                        />)
                })}
                <div style={Styles.divider}>
                    <span>Z  -  {modelVolume[2].toFixed(2)}mm</span>
                    <IconButton onClick={addCut('z')}><AddIcon sx={{ color: '#333' }} /></IconButton>
                </div>
                {values.map((v, index) => {
                    console.log(v, index)
                    const { pos, coord, color } = v;
                    if (coord !== 'z') {
                        return '';
                    }
                    zIndex++;
                    let sliderMax = modelVolume[0]
                    if (coord === 'y') {
                        sliderMax = modelVolume[1]
                    } else if (coord === 'z') {
                        sliderMax = modelVolume[2]
                    }
                    let silde = new SliderObj({ isParent: false, sliderLbl: zIndex, unitLbl: 'mm', initVal: pos, valMax: sliderMax, valMin: 0 })
                    return (
                        <SliderComp
                            value={pos}
                            // getAriaValueText={valuetext}
                            // valueLabelFormat={valuetext}
                            aria-labelledby="discrete-slider"
                            aria-label="cut"
                            valueLabelDisplay="auto"
                            onChangeCommit={handleChange(index)}
                            onIconClick={deleteCut(index)}
                            sliderObj={silde}
                        />)
                })}
                <div style={Styles.footer}></div>
            </div>
        </Draggable>
    )
}

export default Cut