import { createTheme } from '@mui/material';
import baseTheme from './theme.json';
const theme = createTheme({
  ...baseTheme,
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      variants: [
        {
          props: {
            variant: 'filled',
          },
          style: {
            alignItems: 'center',
            backgroundColor: '#fc0441',
            backgroundImage: 'linear-gradient(166deg,#fc0441,#f62da8)',
            border: '3px solid transparent',
            borderRadius: '50px',
            color: '#fff',
            display: 'flex',
            fontSize: '2rem',
            justifyContent: 'center',
            minWidth: '27.75rem',
            padding: '1rem 2rem',
            textTransform: 'uppercase',
            width: 'min-content',
          },
        },
        {
          props: {
            variant: 'outlined',
          },
          style: {
            backgroundColor: 'transparent',
            backgroundImage: 'none',
            border: '3px solid #000',
            borderRadius: '50px',
            color: '#000',
            fontSize: '2rem',
            fontWeight: '600',
            minWidth: '27.75rem',
            padding: '1rem 2rem',
            textTransform: 'uppercase',
            width: 'min-content',
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, Roboto, sans-serif',
        },
        formControl: {
          fontSize: '1.75rem',
          fontWeight: '500',
          // top: '-50%',
          transform: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, Roboto, sans-serif',
        },
        h2: {
          fontWeight: '600',
        },
        h3: {
          fontSize: '1.75rem',
          fontWeight: '500',
          lineHeight: '3.75rem',
        }
      },
    },
  },
});

export default theme;
