import React, { useEffect, useState } from 'react';
import {
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath,
  useParams,
} from 'react-router-dom';
// Custom components
import { MenuIcon } from '@sunsh1n3/slicer-comps';
import * as Comps from '@sunsh1n3/slicer-comps';
import Uploader from '../uploader';
// Material UI
import { styled } from '@mui/material/styles';
// Feather icons
import {
  Codesandbox,
  Layers,
  Plus,
  Settings,
  ShoppingBag,
  Upload,
} from 'react-feather';

//#region Styled components

const Wrapper = styled('div', { label: 'side-nav-wrap' })({
  alignItems: 'center',
  backgroundColor: '#f6f6f6',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  margin: '0',
  padding: '0',
  width: '7.5rem',
});

//#endregion

export default function SideNav(props) {
  let navigate = useNavigate();
  let loc = useLocation();
  const { search, pathname } = loc;
  let [curPath, id] = pathname.slice(1).split('/');
  const [open, setOpen] = useState(false);
  function pathMatch(testPath) {
    return testPath.toLowerCase() === curPath.toLowerCase();
  }

  useEffect(() => {
    setOpen(search.indexOf('upload=true') >= 0)
  }, [search])

  const closeUpload = () => {
    setOpen(false)
    navigate({
      pathname: pathname,
      search: ""
    });

  }

  return (
    <Wrapper>
      {pathname === '/' && <MenuIcon icon={<Plus />} onClick={() => setOpen(true)} />}
      <MenuIcon
        icon={<ShoppingBag />}
        onClick={() => {
          navigate('../', { replace: true });
        }}
        selected={pathMatch('')}
      />
      <MenuIcon
        icon={<Codesandbox />}
        onClick={() => {
          navigate('../slicer', { replace: true });
        }}
        selected={pathMatch('slicer')}
      />
      {/* <MenuIcon icon={<Layers />} /> */}
      <MenuIcon onClick={() => setOpen(true)} icon={<Upload />} selected={open} />
      {/* <MenuIcon icon={<Settings />} /> */}
      <Uploader open={open} projectId={id} onClose={closeUpload} />
    </Wrapper>
  );
}
