

import React from 'react'
import { useLoader, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const CutPlanes = ({ cut, modelVolume, printerVolume }) => {

    return (
        <group position={[printerVolume[0] / 2, printerVolume[1] / 2, modelVolume[2] / 2]}>
            {cut.map((c, index) => {
                const { pos, coord, color } = c;
                let size = modelVolume;
                let rotation = [0, 0, 0];
                let position = [0, 0, 0];
                switch (coord) {
                    case 'x':
                        rotation[1] = -Math.PI / 2;
                        position[0] = pos - modelVolume[0] / 2;
                        size = [modelVolume[2], modelVolume[1]]
                        break;
                    case 'z':
                        rotation[2] = 0;
                        position[2] = pos - modelVolume[2] / 2;
                        size = [modelVolume[0], modelVolume[1]]
                        break;
                    case 'y':
                        rotation[0] = -Math.PI / 2;
                        position[1] = pos - modelVolume[1] / 2;
                        size = [modelVolume[0], modelVolume[2]]
                        break;
                    default:
                        break;
                }

                size[0] += 5;
                size[1] += 5;

                return (<mesh key={index} rotation={rotation} position={position}>
                    <planeGeometry attach="geometry" args={size} />
                    <meshBasicMaterial transparent opacity={0.8} color={'#' + color} side={THREE.DoubleSide} attach="material" />
                </mesh>)
            })}
        </group>
    );
}

export default CutPlanes;

