/** @format */

import React from 'react'

const Styles = {
    root: {
        position: 'fixed', bottom: 5, right: 20, color: 'black', fontSize: '1.5em'
    },
    
};

const Version = () => {

    return (
        <div style={Styles.root} >v {process.env.REACT_APP_VERSION}</div>
    )
}

export default Version



