import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';


export default function BasicCard({ open = false, job = {}, handleClose = () => { } }) {
    if (open !== true || !job.hasOwnProperty("type")) {
        return null
    }

    const { type, status, percent } = job;

    return (
        <Card sx={{ minWidth: 350, zIndex: 10, position: 'absolute', top: 0, right: 0, margin: '2rem' }} >
            <CardContent sx={{ padding: '5px 16px 0px 16px', textAlign: 'left' }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {type.toTitleCase()}
                </Typography>
                <Typography variant="h5" component="div">
                    Status: {status.toTitleCase()}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress variant="determinate" value={percent} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                        <Typography variant="body2" color="text.secondary">{`${Math.round(
                            percent,
                        )}%`}</Typography>
                    </Box>
                </Box>
            </CardContent>
            <CardActions disableSpacing sx={{ paddingTop: 0, justifyContent: 'end' }}>
                <Button size="medium" onClick={handleClose}>Dismiss</Button>
            </CardActions>
        </Card>
    );
}