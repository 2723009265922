import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputAdornment from '@mui/material/InputAdornment';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HomeIcon from '@mui/icons-material/Home';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Paper, TextField, Typography } from '@mui/material';
import ConfirmDialog from '../../components/confirm';



const Wrapper = styled('div', { label: 'pg-home-wrap' })({
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    padding: '2rem'
});

const TempTextField = styled(TextField, { label: 'pg-printer-textfield' })({
    margin: '0 5px',
    height: 43.3,
    maxWidth: 100,
    '& div': { height: 43.3 },
    '& label': {
        fontSize: '1.5rem',
        left: 15,
        top: -6
    }
});

export default function DataTable({ ws, userData }) {
    const { userId } = userData;
    const [printers, setPrinters] = useState([]);
    const [distance, setDistance] = useState("10");
    const [nozzleTemp, setNozzleTemp] = useState('');
    const [bedTemp, setBedTemp] = useState('');

    const [dialog, setDialog] = useState({ open: false });


    useEffect(() => {
        getPrinters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (printers && ws) {
            ws.on('printerUpdate', handlePrinterUpdate);
            ws.emit('printer', printers);
            return () => ws.off('printerUpdate', handlePrinterUpdate);
        }
    }, [ws, printers]);

    const handlePrinterUpdate = (data) => {
        setPrinters(prevPrinters => {
            const newPrinters = prevPrinters.map(printer => {
                if (printer.id === data.printerId) {
                    printer = { ...printer, ...data };
                }
                return printer;
            });
            return newPrinters;
        });
    }


    const columns = [
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'model', headerName: 'Model', width: 250 }, {
            field: 'controls',
            headerName: 'Controls',
            width: 650,
            renderCell: (params) => {
                const printerId = params.row.id;
                return <>
                    <table>
                        <tbody>
                            <tr>
                                <td></td>
                                <td><IconButton aria-label="Forward" onClick={handleControl(printerId, "forward")}>
                                    <KeyboardArrowUpIcon fontSize="large" />
                                </IconButton></td>
                                <td></td>
                                <td><IconButton aria-label="Up" onClick={handleControl(printerId, "up")}>
                                    <KeyboardArrowUpIcon fontSize="large" />
                                </IconButton></td>
                            </tr>
                            <tr>
                                <td><IconButton aria-label="Left" onClick={handleControl(printerId, "left")}>
                                    <KeyboardArrowLeftIcon fontSize="large" />
                                </IconButton></td>
                                <td><IconButton aria-label="Left" onClick={handleControl(printerId, "home")}>
                                    <HomeIcon fontSize="large" />
                                </IconButton></td>
                                <td><IconButton aria-label="Right" onClick={handleControl(printerId, "right")}>
                                    <KeyboardArrowRightIcon fontSize="large" />
                                </IconButton></td>
                                <td><IconButton aria-label="Left" onClick={handleControl(printerId, "homeZ")}>
                                    <HomeIcon fontSize="large" />
                                </IconButton></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><IconButton aria-label="Back" onClick={handleControl(printerId, "back")}>
                                    <KeyboardArrowDownIcon fontSize="large" />
                                </IconButton></td>
                                <td></td>
                                <td><IconButton aria-label="Down" onClick={handleControl(printerId, "down")}>
                                    <KeyboardArrowDownIcon fontSize="large" />
                                </IconButton></td>
                            </tr>
                            <tr style={{ fontSize: '1.5rem' }}>
                                <td></td>
                                <td>X/Y</td>
                                <td></td>
                                <td>Z</td>
                            </tr>
                        </tbody>
                    </table>
                    <ToggleButtonGroup value={distance}
                        exclusive
                        onChange={(e, newValue) => setDistance(newValue)}
                        aria-label="text alignment">
                        <ToggleButton value=".1" aria-label="left aligned">
                            0.1 mm
                        </ToggleButton>
                        <ToggleButton value="1" aria-label="centered">
                            1 mm
                        </ToggleButton>
                        <ToggleButton value="10" aria-label="right aligned">
                            10 mm
                        </ToggleButton>
                        <ToggleButton value="25" aria-label="justified">
                            25 mm
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <form onSubmit={handleControl(printerId, "nozzleTemp")}>
                        <TempTextField
                            label="Nozzle Temp"
                            value={nozzleTemp}
                            onChange={e => setNozzleTemp(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">°C</InputAdornment>,
                                endAdornment:
                                    < InputAdornment position="end" >
                                        <IconButton
                                            aria-label="submit temp change"
                                            onClick={handleControl(printerId, "nozzleTemp")}
                                            edge="end"
                                        >
                                            <ArrowForwardIcon />
                                        </IconButton>
                                    </InputAdornment>

                            }}

                        />
                    </form >
                    <form onSubmit={handleControl(printerId, "bedTemp")}>
                        <TempTextField
                            label="Bed Temp"
                            value={bedTemp}
                            onChange={e => setBedTemp(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">°C</InputAdornment>,
                                endAdornment:
                                    < InputAdornment position="end" >
                                        <IconButton
                                            aria-label="submit temp change"
                                            onClick={handleControl(printerId, "bedTemp")}
                                            edge="end"
                                        >
                                            <ArrowForwardIcon />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </form>
                </>
            },
        },
        {
            field: 'nozzleTemp', headerName: 'Nozzle', renderCell: (params) => {
                return Math.round(params.row.nozzleTemp) + '°C';
            }
        },
        {
            field: 'bedTemp', headerName: 'Bed', renderCell: (params) => {
                return Math.round(params.row.bedTemp) + '°C';
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            // width: 500,
            renderCell: (params) => {
                return <>
                    <IconButton aria-label="delete" onClick={deletePrinter(params.row.id)}>
                        <DeleteIcon fontSize="large" />
                    </IconButton>
                </>
            }
        }
    ];

    const deletePrinter = id => () => {
        let opt = {
            open: true,
            title: "Delete Printer",
            msg: "Are you sure you want to delete this printer, This can't be undone.",
            data: { type: 'deletePrinter', id }
        }
        setDialog(opt);
    }

    const handleControl = (id, action) => (e) => {
        e.preventDefault();
        console.log(id, action);
        const body = {
            id: id,
            command: 'move',
            params: {
                direction: action,
                distance
            }
        }
        if (action === 'home') {
            body.command = 'homeall'
            delete body.params;
        }
        if (action === 'homez') {
            body.command = 'homez'
            delete body.params;
        }
        if (action === 'nozzleTemp') {
            body.command = 'nozzle'
            body.params = {
                temp: nozzleTemp
            }
        }

        if (action === 'bedTemp') {
            body.command = 'bed'
            body.params = {
                temp: bedTemp
            }
        }

        let url = `/api/customerprinter/printercommands/`
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            return res.json();
        }).then(resp => {
            console.log(resp)
        }).catch(e => {
            console.error(e);
        });
    }

    const getPrinters = () => {
        let url = `api/customerprinter/user/${userId}`
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            return res.json();
        }).then(resp => {
            resp.forEach(line => {
                console.log(resp);
                if (!line.nozzleTemp) {
                    line.nozzleTemp = 0;
                }
                if (!line.bedTemp) {
                    line.bedTemp = 0;
                }
            });
            setPrinters(resp);
            ws.emit('printers', resp);
        }).catch(e => {
            console.error(e);
        });

    }


    const handleConfirm = (data) => {
        const { type, id } = data;
        if (type === 'deletePrinter') {
            let url = `api/customerprinter/printer/${id}`
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then((res) => {
                return res.json();
            }).then(resp => {
                console.log(resp);
            }).catch(e => {
                console.error(e);
            });
        }
        setDialog({ open: false });
    }

    const handleCancel = () => {
        setDialog({ open: false });
    }

    return (
        <Wrapper>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <div style={{ width: '100%' }}><Typography variant="h1">My Printers</Typography></div>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ height: 600, padding: 10, width: '95%', margin: 'auto', fontSize: '3rem !important' }}>
                        <DataGrid
                            rows={printers}
                            columns={columns}
                            density='comfortable'
                            sx={{ fontSize: '3rem !important', border: 'none' }}
                            rowHeight={100}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 20 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 20, 50]}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <ConfirmDialog onConfirm={handleConfirm} onCancel={handleCancel}  {...dialog} />
        </Wrapper>
    );
}
