import Emitter from "../../utils/events";

const handleKeypress = (event) => {
    const key = event.keyCode || event.charCode;
    switch (key) {
      // Move the selected object along the X axis
      // case 'ArrowRight':
      //     transformControls.setMode('translate');
      //     transformControls.showX = true;
      //     transformControls.showY = false;
      //     transformControls.showZ = false;
      //     break;

      // case 'ArrowLeft':
      //     transformControls.setMode('translate');
      //     transformControls.showX = true;
      //     transformControls.showY = false;
      //     transformControls.showZ = false;
      //     break;

      // // Move the selected object along the Y axis
      // case 'ArrowUp':
      //     transformControls.setMode('translate');
      //     transformControls.showX = false;
      //     transformControls.showY = true;
      //     transformControls.showZ = false;
      //     break;

      // case 'ArrowDown':
      //     transformControls.setMode('translate');
      //     transformControls.showX = false;
      //     transformControls.showY = true;
      //     transformControls.showZ = false;
      //     break;

      // // Move the selected object along the Z axis
      // case 'KeyW':
      //     transformControls.setMode('translate');
      //     transformControls.showX = false;
      //     transformControls.showY = false;
      //     transformControls.showZ = true;
      //     break;

      // case 'KeyS':
      //     transformControls.setMode('translate');
      //     transformControls.showX = false;
      //     transformControls.showY = false;
      //     transformControls.showZ = true;
      //     break;

      // // Rotate the selected object along the X axis
      // case 'KeyX':
      //     transformControls.setMode('rotate');
      //     transformControls.showX = true;
      //     transformControls.showY = false;
      //     transformControls.showZ = false;
      //     break;

      // // Rotate the selected object along the Y axis
      // case 'KeyY':
      //     transformControls.setMode('rotate');
      //     transformControls.showX = false;
      //     transformControls.showY = true;
      //     transformControls.showZ = false;
      //     break;

      // // Rotate the selected object along the Z axis
      // case 'KeyZ':
      //     transformControls.setMode('rotate');
      //     transformControls.showX = false;
      //     transformControls.showY = false;
      //     transformControls.showZ = true;
      //     break;

      // // Scale the selected object up
      // case 'Equal':
      //     transformControls.setMode('scale');
      //     transformControls.showX = true;
      //     transformControls.showY = true;
      //     transformControls.showZ = true;
      //     break;

      // // Scale the selected object down
      // case 'Minus':
      //     transformControls.setMode('scale');
      //     transformControls.showX = true;
      //     transformControls.showY = true;
      //     transformControls.showZ = true;
      //     break;
      case 27:
        Emitter.emit('clearSelected');
        break;
      case 67:
        Emitter.emit('cutSelected');
        break;
      case 82:
        Emitter.emit('rotateSelected');
        break;
      case 83:
        Emitter.emit('scaleSelected');
        break;
      case 84:
        Emitter.emit('transformSelected');
        break;
      // Delete the selected object
      case 46: // Delete
      case 8: // Backspace
        Emitter.emit('deleteSelected');
        break;
      default:
        break;
    }
  };

  export {handleKeypress};