import React, { useEffect, useState } from 'react';
// Custom components
// Material UI
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ProjectCard from '../../components/projectCard';
import NewProjectCard from '../../components/projectCard/newProject';
import Version from '../../components/version';
import Emitter from '../../utils/events';
import { object } from 'prop-types';

//#region Styled components
const Wrapper = styled('div', { label: 'pg-home-wrap' })({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  width: '100%',
});
//#endregion

let listening = false;

const cardColors = ['#FF633E', '#97FF3E', '#FFC905', '#05FFFF', '#CE72FF', '#FF74BF']

export default function Home({ userData, ws }) {
  const [projects, setProjects] = useState([]);
  const [recentProject, setRecentProject] = useState(null);
  useEffect(() => {
    getProjects();
    Emitter.on('uploadComplete', getProjects);
    return () => {
      Emitter.off('uploadComplete', getProjects);
    }
  }, []);

  const getProjects = (projectId) => {
    // console.log('get projects ', projectId)
    if (projectId) {
      setRecentProject(projectId);
    }
    let url = '/api/projects/' + userData.userId;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        setProjects(resp);
      });
  };

  useEffect(() => {
    if (listening === false && recentProject) {
      listening = true;
      // console.log('in ws handler, ', recentProject);
      ws.on('projectUpdate', updateProject);
      ws.emit('project', recentProject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentProject]);

  const updateProject = (data) => {
    // console.log('updateProject ', data)
    if (data.status === 'complete') {
      ws.off('projectUpdate', updateProject);
      setRecentProject(null);
      listening = false;
      setProjects(temp => {
        return temp.map(project => {
          if (project.id === recentProject) {
            project = Object.assign({}, project);
            project.updated = true;
          }
          return project;
        })
      });
    }
  }

  const newProject = () => {

  }

  return (
    <Wrapper>
      {/* <HomeComp /> */}
      <div
        style={{
          backgroundColor: 'gainsboro',
          flexGrow: '1',
          height: '100%',
          width: '100%',
          padding: '0 4rem',
          overflow: 'scroll'
        }}
      >
        <h1>My Projects</h1>
        <Version />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ marginTop: '8rem' }}
        >
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <NewProjectCard onClick={newProject} />
          </Grid>
          {projects.map((project, idx) => {
            const color = cardColors[idx % cardColors.length];
            project.color = color;
            return (<Grid key={project.id} item xs={12} sm={6} md={4} lg={3}>
              <ProjectCard onChange={getProjects} project={project} user={userData} />
            </Grid>)
          })}
        </Grid>
      </div>
    </Wrapper >
  );
}
