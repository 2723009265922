import React, { useState, useRef } from 'react';

//import mui components
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import './upload.css';
import Emitter from '../../utils/events';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType)


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  minHeight: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 5
};

let singleton;

export default function BasicModal({ open, onClose, projectId }) {
  const handleClose = () => onClose();
  const [project, setProject] = useState({ name: '', description: '' });

  const createProject = (fileData) => {
    let { name, description } = project;
    if (!name) {
      console.log(fileData)

    }
    let url = '/api/project/';
    let body = {
      name,
      tags: [],
      description,
      permissions: 'private',
      files: [],
      sharedWidth: [],
      metaData: {}
    }

    if (singleton) {
      return singleton;
    } else {
      singleton = fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async res => {
          if (!res.ok) {
            // get error message from body or default to response status
            console.log(res);
            return Promise.reject(await res.text());
          } else {
            return res.json();
          }
        })
        .then(data => {
          singleton = undefined;
          return data.id;
        })
        .catch(err => {
          singleton = undefined;
          console.error(err);
        });
      return singleton;
    }


  }

  const handleChange = key => (e) => {
    const v = e.target.value;
    let tmp = {}
    tmp[key] = v;
    setProject({ ...project, ...tmp })
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!projectId &&
            <form style={{ padding: '4rem 0', width: '100%' }}>
              <TextField id="name" label="Name" variant="standard" value={project.name} onChange={handleChange('name')} fullWidth />
              <br /><br />
              <TextField
                id="description"
                label="Description"
                variant="standard"
                multiline
                fullWidth
                value={project.description}
                onChange={handleChange('description')}
                rows={4} />
            </form>
          }
          <Uploader projectId={projectId} createProject={createProject} />
        </Box>
      </Modal>
    </div>
  );
}



// Our app
function Uploader({ projectId, createProject }) {
  const [files, setFiles] = useState([]);
  const newProjectId = useRef(projectId);

  const processFiles = async (fieldName, file, metadata, load, error, progress, abort) => {
    let projectData = { projectId: newProjectId.current };
    // console.log(projectData)
    const formData = new FormData();
    formData.append('upfile', file);
    formData.append('projectData', JSON.stringify(projectData));
    const request = new XMLHttpRequest();
    request.open('POST', '/api/file');
    request.upload.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total);
    };
    request.onload = function () {
      if (request.status >= 200 && request.status < 300) {

        load(request.responseText);

        let data = JSON.parse(request.responseText);
        console.log(data);
        Emitter.emit('uploadComplete', projectData.projectId);
        // addFiletoProject(data.id, projectData.projectId);
      } else {
        error('oh no');
      }
    };

    request.send(formData);

  }

  const getProjectId = async (item) => {
    if (!newProjectId.current) {
      let tmp = await createProject(item.file);
      newProjectId.current = tmp;
      return true;
    } else {
      return true;
    }
  }

  return (
    <div style={{ maxHeight: '300px' }}>
      <FilePond
        style={{ height: 300 }}
        files={files}
        onupdatefiles={setFiles}
        server={{
          url: '/api/file',
          process: processFiles,
        }}
        acceptedFileTypes={['application/stl', 'application/step']}
        allowMultiple={true}
        maxFiles={3}
        beforeAddFile={getProjectId}
        credits={{ label: "", url: "" }}
        fileValidateTypeDetectType={(source, type) =>
          new Promise((resolve, reject) => {
            console.log(type);
            const ext = source.name.split('.').pop();
            if (type === "" && ext === 'stl') {
              type = 'application/stl';
            } else if (type === "" && (ext === 'step' || ext === 'stp')) {
              type = 'application/step';
            }
            resolve(type);
          })}
        name="upfile" /* sets the file input name, it's filepond by default */
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </div>
  )
}

