import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
// Custom components
// Material UI
import ButtonBase from '@mui/material/ButtonBase';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from 'notistack';
// React Router
import { Link, useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

//#region Styled components
const FormControlWrapper = styled(FormControl, {
  label: 'register-form-control-wrap',
})({
  alignItems: 'flex-start',
});

const FormWrapper = styled('form', { label: 'register-form-wrap' })({
  height: 'min-content',
  maxWidth: '21.67vw',
  width: '100%',
});

const HeaderWrapper = styled('div', { label: 'register-head-wrap' })({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '4rem',
  width: '100%',
});

const StyledBtn = styled(ButtonBase, { label: 'register-btn' })({
  marginTop: '5.5rem',
  width: '100%',
  '&:hover': {
    boxShadow: '0.5rem 0.5rem 1.5rem #0f0f0f',
  },
});

const StyledInput = styled(InputBase, { label: 'register-inp' })({
  backgroundColor: '#efefef',
  border: '1px solid #ccc',
  borderRadius: '4px',
  color: '#333',
  fontSize: '1.75rem',
  height: '6.75rem',
  marginBottom: '1.25rem',
  padding: '1rem 1.5rem',
  width: '100%',
  '&.Mui-error': {
    borderColor: 'red',
  },
  '&.Mui-focused': {
    borderColor: '#1976d2',
  },
});

const StyledLabel = styled(InputLabel, { label: 'register-inp-lbl' })({
  marginBottom: '0.5rem',
  position: 'relative',
});

const StyledTxt = styled(Typography, { label: 'register-txt' })({
  color: '#333',
  '& a': {
    color: '#0062ff',
  },
  '&.credits': {
    color: '#06c',
    fontSize: '1.25rem',
    marginTop: '3.25rem',
    opacity: '0.26',
  },
});

const Wrapper = styled('div', { label: 'login-wrap' })({
  alignItems: 'center',
  backgroundColor: 'transparent',
  backgroundImage: 'url(/images/model02.png),linear-gradient(#fde34e,#f5b225);',
  backgroundPositionX: 'right -120px',
  backgroundPositionY: 'top',
  backgroundRepeat: 'no-repeat, repeat',
  backgroundSize: '500px,cover',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: '0 39.167vw',
  width: '100%',
});
//#endregion

export default function LoginComp(props) {
  const [userEmail, setUserEmail] = useState('');
  const emailRef = useRef(null);
  const [password, setPassword] = useState('');
  const passwordRef = useRef(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  function handleFormSubmit() {
    setDisableSubmit(true);
    if (passwordRef.current.validity.valueMissing ?? true) {
      passwordRef.current.setCustomValidity('Please enter your password');
      passwordRef.current.checkValidity();
      passwordRef.current.reportValidity();
    } else if (emailRef.current.validity.valueMissing ?? true) {
      emailRef.current.setCustomValidity(
        "Please enter your account's email address"
      );
      emailRef.current.checkValidity();
      emailRef.current.reportValidity();
    } else {
      fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: userEmail,
          password: password,
        }),
      })
        .then(async (resp) => {
          if (resp.ok) {
            return resp.json()
          } else {
            throw await resp.text();
          }
        })
        .then((user) => {
          localStorage.setItem('user', JSON.stringify(user));
          const { jwt } = user;
          if (jwt) {
            cookies.set('JWT', jwt, {
              path: '/',
              sameSite: 'strict',
              secure: true,
            });
          } else {
            cookies.remove('JWT');
          }
          window.location.href = '/';
        }).catch(err => {
          enqueueSnackbar(err, { variant: 'error' })
        });
    }

    setDisableSubmit(false);
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <StyledTxt variant='h2'>Sign In</StyledTxt>
        <StyledTxt variant='h3'>
          New user? <Link to={'/register'}>Create an account</Link>
        </StyledTxt>
      </HeaderWrapper>
      <FormWrapper onSubmit={handleFormSubmit}>
        <FormControlWrapper
          error={
            !(userEmail === '') &&
            !emailRef.current?.validity.valid &&
            !emailRef.current?.validity.valueMissing
          }
          fullWidth
          required={true}
          variant='outlined'
        >
          <StyledLabel disableAnimation={true} shrink={true} variant='outlined'>
            Email Address
          </StyledLabel>
          <StyledInput
            id='inp-email'
            inputRef={emailRef}
            name='inp-email'
            onChange={(e) => setUserEmail(e.target.value)}
            type='email'
          />
        </FormControlWrapper>
        <FormControlWrapper
          error={
            !(password === '') &&
            !passwordRef.current?.validity.valid &&
            !passwordRef.current?.validity.valueMissing
          }
          fullWidth
          required={true}
          variant='outlined'
        >
          <StyledLabel disableAnimation={true} shrink={true} variant='outlined'>
            Password
          </StyledLabel>
          <StyledInput
            autoComplete='new-password'
            endAdornment={
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            }
            id='inp-pass'
            inputRef={passwordRef}
            name='inp-pass'
            onChange={(event) => setPassword(event.target.value)}
            required={true}
            type={showPassword ? 'text' : 'password'}
          />
        </FormControlWrapper>
        <StyledBtn
          disabled={disableSubmit}
          onClick={() => handleFormSubmit()}
          variant='filled'
        >
          Login
        </StyledBtn>
      </FormWrapper>
      <StyledTxt
        className='credits'
        component='a'
        href='https://sketchfab.com/3d-models/asura-figure-8b90cb016e2e401b8b4ec1f006b8f42b'
        target='_blank'
      >
        Image source: SketchFab, Isaiah Paul lock.
      </StyledTxt>
    </Wrapper>
  );
}
