/** @format */

import React from 'react'

import { PrintTimeCard } from '@sunsh1n3/gcode-slicer-comps';

const GcodeStats = ({ file }) => {
    const { printTime = 0, materialVolume = 0, density = 0.00125, diameter = 1.75 } = file;
    let mass = materialVolume * density;
    let m = calculateCylinderHeight(materialVolume, diameter / 2) / 1000;
    return (
        <PrintTimeCard
            timeRemaining={new Date(printTime * 1000).toISOString().substring(11, 16)}
            matWeight={mass.toFixed(2) + 'g'}
            matLength={Math.round(m) + 'm'} />
    )
}

export default GcodeStats



const calculateCylinderHeight = (volume, radius) => {
    const height = volume / (Math.PI * radius ** 2);
    return height;
}
