const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  }
  let localOverides = {}
if(window.localStorage) {
  try {
    localOverides = JSON.parse(window.localStorage.getItem('overides')) || {};
  } catch (e) {
    localStorage.setItem('overides',JSON.stringify(localOverides));
  }
}


if (typeof localOverides !== 'object') {
  localOverides = {};   
}




String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

  export {debounce,localOverides};