/** @format */

import React, { useEffect, useState } from 'react'

import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';


const Styles = {
    root: {
        position: 'absolute',
        display: 'flex',
        top: 50,
        right: 50
    },
    tools: {
        width: 100,
        height: 300,
        alignSelf: 'center',
        margin: 'auto',
        color: 'white',
        zIndex: 100,
    },
    button: {
        color: 'white',
        margin: '.25em .5em',
    },
    toolTip: {
        fontSize: '2em'
    },
    active: {
        borderColor: '#174CFF',
    },
};

const Views = ({ onChange, max = 1, show = false }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        // let n = exponentialFactor(newValue);
        setValue(newValue);
        onChange(max - newValue)
    };

    useEffect(() => {
        if (max > 0) {
            setValue(max);
        }
    }, [max])


    return (
        <>
            {show && max > 0 && <div style={Styles.root} >
                <Box sx={Styles.tools}>
                    <Slider
                        value={value}
                        // getAriaValueText={valuetext}
                        // valueLabelFormat={valuetext}
                        aria-labelledby="discrete-slider"
                        aria-label="scale"
                        valueLabelDisplay="on"
                        orientation="vertical"
                        onChange={handleChange}
                        name="Scale"
                        min={0}
                        max={max}
                    />
                </Box>
            </div >}
        </>
    )
}

export default Views
