import React, { useState } from 'react';
// Custom components
import { Link } from 'react-router-dom';

// Material UI
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

//#region Styled Comps

const Logo = styled('img', { label: 'logo' })({
  height: '3rem',
});

const StyledAvatar = styled(Avatar, { label: 'styled-avatar' })({
  height: '4rem',
  width: '4rem',
});

const Wrapper = styled('div', { label: 'top-nav-wrapper' })({
  alignItems: 'center',
  backgroundColor: '#111',
  display: 'flex',
  flexDirection: 'row',
  height: '5.56vh',
  justifyContent: 'space-between',
  padding: '1.25rem 2.5rem',
  width: '100%',
});

//#endregion

export default function TopNav({ userData }) {


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    handleClose();
    localStorage.clear();
    cookies.remove('JWT');
    cookies.remove('USER');
    window.location.href = '/';

  }

  return (
    <Wrapper>
      <Logo alt='Slicer by Printbed' src='/images/slicer-logo@2x.png' />
      <IconButton onClick={handleClick}>
        <StyledAvatar src={userData.avatar} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar src={userData.avatar} />  Profile
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to={'/printers'}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          My Printers
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Wrapper>
  );
}
