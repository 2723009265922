import React, { useState, useEffect, useRef } from 'react';
// import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useSnackbar } from 'notistack';

// Custom components
import SlicerTopBar from '../../components/slicerTopBar';

import Views from '../../components/views';
import Tools from '../../components/tools';
import Resize from '../../components/resize';
import Cut from '../../components/cut';
import ClipGcode from '../../components/clipgcode';
import ContextMenu from '../../components/slicer/contextMenu';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FileInfoCard, PartSelector } from '@sunsh1n3/gcode-slicer-comps';
import { InfillPopup, InfillOptObj } from '@sunsh1n3/slicer-comps';
// Material UI
import { styled } from '@mui/material/styles';
import './loader.css';
import { useParams, useSearchParams } from 'react-router-dom';
// import Viewer from '../../components/viewer';
// import Slicer from '../../components/slicer';
import ConfirmDialog from '../../components/confirm';
import LoadingDialog from '../../components/loading/cardVersion';
import GcodeStats from '../../components/gcodeStats';
import Emitter from '../../utils/events';
import { handleKeypress } from './helpers';
import SettingsComp from '../../components/settings';
import Version from '../../components/version';
import { localOverides } from '../../utils/common';
import Slicer from '../../components/slicer';

let loading = '';
//#region Styled components
const Wrapper = styled('div', { label: 'pg-home-wrap' })({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
});
//#endregion

const PillButton = styled(Button, { label: 'pg-home-wrap' })({
  borderRadius: 20,
  minWidth: 150
});


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 300,
  padding: theme.spacing(0, 1),
  fontSize: '3rem',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function SlicerPage(props) {
  const { ws, userData } = props;
  const { userId, jwt } = userData;
  const { projectId } = useParams();
  const [URLSearchParams] = useSearchParams();
  const [view, setView] = useState('iso');
  const [activeTool, setActiveTool] = useState('select');
  const [mode, setMode] = useState('model');
  const [scale, setScale] = useState([1, 1, 1]);
  const [cut, setCut] = useState([{ pos: 0, coord: 'x' }]);
  const [project, setProject] = useState({});
  const [stageFiles, setStagefiles] = useState([])
  const [mainFiles, setMainFiles] = useState([]);
  const [partFiles, setPartFiles] = useState([]);
  const [cutFiles, setCutFiles] = useState([]);
  const [gcodeFiles, setGcodeFiles] = useState([]);
  const [selectedGcode, setSelectedGcode] = useState(0);
  const [openSettings, setOpenSettings] = useState(false);
  const selected = useRef([]);
  const [dialog, setDialog] = useState({});
  const [loadingScreen, setLoading] = useState(false);
  const [modelVolume, setModelVolume] = useState([0, 0, 0]);
  const [printerVolume, setPrinterVolume] = useState([220, 220, 250]);
  const [printerShape, setPrinterShape] = useState('rectangular');
  const [percent, setPercent] = useState(0);
  const [clip, setClip] = useState(0);
  const [maxZ, setMaxZ] = useState(0)
  const [printer, setPrinter] = useState({ name: '' });
  const [overrides, setOverrides] = useState(localOverides);
  const [jobData, setJobData] = useState();
  const [userPrinters, setUserPrinters] = useState([]);
  const [infillOptions, setInfillOptions] = useState([]);
  const [contextMenu, setContextMenu] = React.useState(null);
  const exportStage = useRef({});
  const fileIdToStage = URLSearchParams.get("fileId");

  useEffect(() => {
    //get project from id
    if (project.id !== projectId && projectId !== loading) {
      getProject();
      if (ws) {
        ws.emit('project', projectId);
      }
    }
    //eslint-disable-next-line
  }, [projectId, project.id]);


  useEffect(() => {
    getPrinters();
  }, []);

  useEffect(() => {
    if (ws) {
      ws.on('projectUpdate', handleProjectUpdate);
      ws.emit('project', projectId);
      // console.log('ws')
      return () => ws.off('projectUpdate', handleProjectUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws]);

  useEffect(() => {
    localStorage.setItem('overides', JSON.stringify(overrides));
  }, [overrides])


  const getPrinters = () => {
    let url = `/api/customerprinter/user/${userId}`
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res) => {
      return res.json();
    }).then(resp => {
      setUserPrinters(resp);
    }).catch(e => {
      console.error(e);
    });

  }

  const handleProjectUpdate = (data) => {
    // console.log("project update ", data)
    let { status, percent: p, jobType = 'part', type, fileId } = data;
    if (type === 'gcode') {
      jobType = 'gcode'
    }
    if (status === 'running' && p > percent) {
      setPercent(p);
      setJobData(data);
    } else if (status === 'complete' && type !== "preview") {
      getProjectFiles(jobType);
      setPercent(0);
      setLoading(false);
      setJobData(undefined);
    } else if (type === 'preview') {
      setCutFiles(prevFiles => {
        let tmp = [...prevFiles];
        tmp.forEach(part => {
          if (part.id === fileId) {
            part.previewImgSrc = `/api/file/${fileId}/preview?ts=${Date.now()}`
          }
        })
        return tmp;
      });
      setPartFiles(prevFiles => {
        let tmp = [...prevFiles];
        tmp.forEach(part => {
          if (part.id === fileId) {
            part.previewImgSrc = `/api/file/${fileId}/preview?ts=${Date.now()}`
          }
        })
        return tmp;
      });
    }
  }

  const getProject = () => {
    loading = projectId;
    let url = '/api/project/' + projectId;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        setProject(resp);
        loading = '';
        if (resp.files) {
          resp.files.forEach(file => {
            getfileData(file);
          });
          getProjectFiles('part');
          getProjectFiles('cut');
          getProjectFiles('gcode');
        }
      });
  }

  const getfileData = (file) => {
    let url = '/api/file/' + file.id;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        sortFiles(resp);
      });
  }

  const sortFiles = (file) => {
    const { data } = file;
    if (data && data.type === 'part') {
      let part = { ...data, id: file.id, previewImgSrc: '/api/file/' + file.id + '/preview', name: file.name, time: '' }
      setPartFiles([part, ...partFiles]);
    } else if (data && data.type === 'cut') {
      let cut = { ...data, id: file.id, previewImgSrc: '/api/file/' + file.id + '/preview', name: file.name, time: '' }
      setCutFiles([cut, ...cutFiles]);
    } else if (data && data.type === 'gcode') {
      let cut = { ...data, id: file.id, previewImgSrc: '/api/file/' + file.id + '/preview', name: file.name, time: '' }
      setGcodeFiles([cut, ...gcodeFiles]);
    } else {
      setMainFiles([file, ...mainFiles]);
      initialStage([file, ...mainFiles]);
    }
  }

  const getProjectFiles = (type) => {
    let filter = { "data.projectId": projectId }
    if (type) {
      filter["data.type"] = type;
    }
    let url = '/api/files?skip=0&limit=50&sort=_system.created&dir=-1&search=' + encodeURIComponent(JSON.stringify(filter)) + '&ts=' + new Date().getTime();
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        let tmp = resp.map(part => {
          return { ...part.data, id: part.id, previewImgSrc: '/api/file/' + part.id + '/preview', name: part.name, time: '' }
        });
        if (tmp.length > 0) {
          if (type === 'part') {
            setPartFiles(tmp);
            initialStage(tmp);
          } else if (type === 'cut') {
            setCutFiles(tmp);
            if (fileIdToStage) {
              initialStage(tmp);
            } else {
              initialStage([tmp[0]]);
            }
          } else if (type === 'gcode') {
            setGcodeFiles(tmp);
          }
        }
      });
  }

  const initialStage = (files) => {
    if (fileIdToStage) {
      files.forEach(file => {
        if (file.id === fileIdToStage) {
          setStagefiles([file]);
        }
      })
    } else {
      setStagefiles(files);
    }
  }

  useEffect(() => {
    if (activeTool === 'split') {
      splitFile();
    }

    if (activeTool === 'settings') {
      toggleSettings();
    }

  }, [activeTool])


  const splitFile = () => {
    let opt = {
      open: true,
      title: "Split File",
      msg: "Are you sure you want to split this file, it might take a little bit.",
      data: { type: 'split' }
    }
    setDialog(opt);
    setActiveTool('select');
  }


  const deleteSelected = () => {
    if (selected.current.length > 0) {
      let sel = selected.current;
      setStagefiles(files => {
        let tmp = [...files];
        sel.forEach(id => {
          const index = tmp.findIndex(item => item.id === id);
          if (index !== -1) {
            tmp.splice(index, 1);
          }
        });
        return tmp;
      });
      selected.current = [];
    }
  };

  const handleConfirm = (data) => {
    if (data.type === 'split') {
      let data = {
        "type": "split",
        "fileId": mainFiles[0].id,
        "projectId": projectId,
        "refId": mainFiles[0].id,
        "fileName": mainFiles[0].name
      }

      let url = '/api/jobs';
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(function (res) {
          return res.json();
        })
        .then(resp => {
          // setFiles([resp, ...files]);
          setJobData({ type: 'split', percent: 0, status: 'queued' });
          setLoading(true)
        });
    } else if (data.type === 'cut') {
      cutFile();
    }
    setDialog({ open: false });
  }

  const cutFile = () => {
    if (exportStage.current) {
      const blob = exportStage.current.exportFiles();
      const formData = new FormData();
      var fileOfBlob = new File([blob], "slice.stl");
      formData.append('upfile', fileOfBlob);
      fetch('/api/file/?ttl=' + 3600, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      }).then(function (res) {
        return res.json();
      }).then(sendCutJob);
    } else {
      console.log('no current')
    }
  }

  const sendCutJob = (file) => {
    let data = {
      "type": "cut",
      "fileId": file.id,
      "refId": mainFiles[0].id,
      "projectId": projectId,
      "fileName": mainFiles[0].name,
      "cuts": cut
    }

    setJobData({ type: 'cut', percent: 0, status: 'queued' });
    let url = '/api/jobs';
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        setLoading(true)
      });
  }

  const handleCancel = () => {
    setDialog({ open: false });
  }

  const handleCut = () => {

    let opt = {
      open: true,
      title: "Cut File",
      msg: "Are you sure you want to cut this file, it might take a little bit.",
      data: { type: 'cut' }
    }
    setDialog(opt)
    setActiveTool('select');
  }

  useEffect(() => {
    document.addEventListener('keyup', handleKeypress);
    Emitter.on('deleteSelected', deleteSelected);
    Emitter.on('transformSelected', activateTool('move'));
    Emitter.on('scaleSelected', activateTool('resize'));
    Emitter.on('rotateSelected', activateTool('rotate'));
    Emitter.on('cutSelected', activateTool('cut'));
    Emitter.on('clearSelected', activateTool('select'));
    Emitter.on('contextMenu', handleContextMenu);

    return () => {
      document.removeEventListener('keyup', handleKeypress);
      Emitter.off('deleteSelected', deleteSelected);
      Emitter.off('transformSelected', activateTool('move'));
      Emitter.off('scaleSelected', activateTool('resize'));
      Emitter.off('rotateSelected', activateTool('rotate'));
      Emitter.off('cutSelected', activateTool('cut'));
      Emitter.off('contextMenu', handleContextMenu);
    }
  }, []);

  const activateTool = tool => () => {
    setActiveTool(tool);

  }

  const slice = () => {
    setLoading(true);
    if (exportStage.current) {
      const blob = exportStage.current.exportFiles(printer.overrides?.machine_center_is_zero?.default_value);
      const formData = new FormData();
      var fileOfBlob = new File([blob], "slice.stl");
      formData.append('upfile', fileOfBlob);
      fetch('/api/file/?ttl=' + 3600, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      }).then(function (res) {
        return res.json();
      }).then(sendSliceJob);
    } else {
      console.log('no current')
    }
  }

  const sendSliceJob = (fileData) => {
    const body = {
      fileId: fileData.id,
      projectId,
      type: 'slice',
      settings: { overrides, printer: { id: printer.id, definitionName: printer.definitionName } }
    }
    fetch('/api/jobs', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(function (res) {
      return res.json();
    }).then(jobData => {
      console.log(jobData)
      setJobData({ type: 'gcode', percent: 0, status: 'queued' });
    });
  }

  const addFiletoStage = (id) => {
    //@todo make a file map
    let index = cutFiles.findIndex(item => item.id === id);
    if (index !== -1) {
      console.log(cutFiles[index])
      return setStagefiles([cutFiles[index], ...stageFiles]);
    }

    index = partFiles.findIndex(item => item.id === id);
    if (index !== -1) {
      console.log(partFiles[index])
      return setStagefiles([partFiles[index], ...stageFiles]);
    }
  }

  const onDeselected = (id) => {
    const index = selected.current.indexOf(id);
    if (index !== -1) {
      selected.current.splice(index, 1);
    }
  }

  const onSelected = (id) => {
    const index = selected.current.indexOf(id);
    if (index === -1) {
      selected.current.push(id);
    }
  }

  const printFile = () => {
    let file = gcodeFiles[selectedGcode];
    const { id: fileId } = file;
    let printerId = userPrinters[0].id;
    const body = {
      id: printerId,
      command: 'print',
      params: {
        fileId,
        jwt: jwt
      }
    }

    let url = `/api/customerprinter/printerCommands/`
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res) => {
      return res.json();
    }).then(resp => {
      console.log(resp)
    }).catch(e => {
      console.error(e);
    });
  }

  const printWithPrintbed = () => {
    let file = gcodeFiles[selectedGcode];
    const { id: fileId } = file;
    const body = {
      material: 'PLA',
      color: 'black',
      params: {
        fileId
      }
    }

    let url = `/api/printjobs/`
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res) => {
      return res.json();
    }).then(resp => {
      console.log(resp)
    }).catch(e => {
      console.error(e);
    });
  }

  const handleContextMenu = (event) => {
    // event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
          fileId: event.fileId
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
        // Other native context menus might behave different.
        // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null,
    );
  };

  const downloadFile = () => {
    let file = gcodeFiles[selectedGcode];
    let url = `/api/file/${file.id}/streams`;
    var link = document.createElement("a");
    let name = project.name + '.gcode';
    link.setAttribute('download', name);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  const onPrinterChange = (printer) => {
    if (printer.overrides && printer.overrides.machine_width) {
      let volume = [printer.overrides.machine_width.default_value, printer.overrides.machine_depth.default_value, printer.overrides.machine_height.default_value]
      let shape = printer.overrides.machine_shape?.default_value || 'rectangular';
      const infillTypes = printer.settings.infill.children.infill_pattern.options;
      let infillOptions = Object.keys(infillTypes).map(key => {
        return new InfillOptObj({
          id: key,
          displayName: infillTypes[key],
          imgUrl: '/images/infill/' + key + '.png'
        });
      });
      setPrinterVolume(volume);
      setPrinterShape(shape)
      setPrinter(printer);
      setInfillOptions(infillOptions);


    } else {
      // console.log(printer)
    }
  }

  const toggleSettings = () => {
    setOpenSettings(!openSettings);
  }

  const topSettingsChange = (setting, value) => {
    setOverrides(prevOverides => {
      let tmp = { ...prevOverides };
      tmp[setting] = value;
      return tmp;
    });
  }

  const handleInfill = (selected) => {
    if (selected) {
      overrides.infill_pattern = selected;
    }
    setActiveTool('select');
  }

  const closeStatus = () => {
    setLoading(false);
  }

  const printbedPrint = () => {
    let url = '/api/printerdefs/?skip=0&limit=50&sort=_system.created&dir=-1&search={"name":"Printbed"}';
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res) => {
      return res.json();
    }).then(resp => {
      console.log(resp)
      loadPrintbedPrinter(resp.resp[0].id)
    }).catch(e => {
      console.error(e);
    });
  }

  const loadPrintbedPrinter = (id) => {
    let url = '/api/printerdef/' + id;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res) => {
      return res.json();
    }).then(resp => {
      console.log(resp)
      onPrinterChange(resp)
    }).catch(e => {
      console.error(e);
    });
  }

  return (
    <Wrapper>
      <SlicerTopBar overrides={overrides} onPrinterChange={onPrinterChange} onClickSettings={toggleSettings} onSettingsChange={topSettingsChange} />

      <div
        style={{
          backgroundColor: 'gainsboro',
          flexGrow: '1',
          height: '100%',
          width: '100%',
          display: 'flex',
          position: 'relative'
        }}
      >
        {activeTool === 'infill' &&
          <InfillPopup isOpen={activeTool === 'infill'} selectedOptId={overrides.infill_pattern || 'grid'} infillOpts={infillOptions} onClose={handleInfill} />
        }
        <ContextMenu contextMenu={contextMenu} onClose={() => { setContextMenu(null) }} />
        {activeTool !== 'cut' && mode === 'model' && <Views onChange={setView} />}
        <Version />
        <Drawer
          anchor="right"
          open={openSettings}
          onClose={() => { setOpenSettings(false) }}
        >
          <DrawerHeader>
            <IconButton onClick={toggleSettings}>
              <ChevronRightIcon sx={{ fontSize: "4rem" }} />
            </IconButton>
            <h4>Settings</h4>
          </DrawerHeader>
          <Divider />
          <SettingsComp onChange={setOverrides} printer={printer} />
        </Drawer>
        <ConfirmDialog onConfirm={handleConfirm} onCancel={handleCancel}  {...dialog} />
        <LoadingDialog open={loadingScreen} handleClose={closeStatus} percent={percent} job={jobData} />
        <Grid container align="left" direction="column" style={{
          display: 'flex',
          position: 'absolute',
          bottom: 0,
          width: '14.7vw',
          height: '100%',
          padding: '30px 0px',
          left: 50,
          zIndex: 100,
          justifyContent: 'flex-end'
        }}>
          {partFiles.length > 0 || cutFiles.length > 0 ?
            <div
              onScroll={(e) => e.stopPropagation()}
              style={{
                display: 'flex',
                maxHeight: 500,
                height: '50vh',
                width: '100%',
                zIndex: 100,
                // marginLeft: -17,
                overflowY: 'scroll'
              }}>
              <PartSelector
                parts={partFiles.length > 0 ? partFiles : cutFiles}
                onPartClick={(data) => {
                  Emitter.emit(data, { type: 'click' });
                  const index = stageFiles.findIndex(item => item.id === data);
                  if (index === -1) {
                    addFiletoStage(data)
                  }
                }}
              />
            </div> : null}
          {mainFiles.length > 0 &&
            <Grid item className="fileCard"><FileInfoCard
              fileImgSrc={mainFiles[0].preview ? mainFiles[0].preview[0].value : "https://via.placeholder.com/250/909090/fff?text=No+Preview+Found"}
              fileName={mainFiles[0].name || ""}
              fileSize={getReadableFileSizeString(mainFiles[0].size)}
              fileTitle={project.name || ""}
            /></Grid>}
          {mode === 'preview' && <GcodeStats file={gcodeFiles[selectedGcode]} />}
        </Grid>



        {mode === 'model' && <Tools activeTool={activeTool} onChange={setActiveTool} />}
        {activeTool === 'resize' && selected.current.length > 0 && <Resize onChange={setScale} modelVolume={modelVolume} />}
        <ClipGcode onChange={setClip} max={maxZ} show={mode === 'preview'} />
        {activeTool === 'cut' && <Cut show={activeTool === 'cut'} onChange={setCut} modelVolume={modelVolume} printerVolume={printerVolume} printerName={printer.name} onApply={handleCut} />}

        <Stack spacing={2} direction="row" style={{ display: 'flex', position: 'absolute', height: 40, bottom: 35, right: 50, zIndex: 100 }}>
          {gcodeFiles.length > 0 && mode !== 'preview' && <PillButton variant="contained" onClick={() => setMode('preview')}>Preview</PillButton>}
          {mode === 'preview' && <PillButton variant="contained" color="secondary" onClick={() => setMode('model')}>Model</PillButton>}
          {mode === 'preview' && <PillButton variant="contained" onClick={downloadFile}>Download gcode</PillButton>}
          {mode === 'preview' && userPrinters.length > 0 && <PillButton variant="contained" onClick={printFile}>Send to Printer</PillButton>}
          {mode === 'preview' && printer.name === 'Printbed' && <PillButton variant="contained" onClick={printWithPrintbed}>Send to Printbed</PillButton>}
          {mode === 'model' && <PillButton variant="contained" onClick={slice}>Slice</PillButton>}
          {/* {mode === 'model' && printer.name !== 'Printbed' && <PillButton color="error" variant="contained" onClick={printbedPrint}>Print with Printbed</PillButton>} */}
        </Stack>
        {project && stageFiles && <Slicer
          project={project}
          onModelVolume={setModelVolume}
          files={stageFiles}
          gcodeFile={gcodeFiles[selectedGcode]}
          view={view}
          activeTool={activeTool}
          scale={scale}
          cut={cut}
          mode={mode}
          onMaxZ={setMaxZ}
          clip={clip}
          printerVolume={printerVolume}
          printerShape={printerShape}
          exportStage={exportStage}
          onSelected={onSelected}
          onDeselected={onDeselected}
        />}
      </div>
      {/* <FileUpload projectId={projectId} /> */}
    </Wrapper>
  );
}


const getReadableFileSizeString = (fileSizeInBytes) => {
  if (!fileSizeInBytes) {
    return "";
  }
  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB'];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}