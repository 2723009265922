import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Custom components
// Material UI
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FileExplorer from '../../components/fileexplorer';

//#region Styled components
const Wrapper = styled('div', { label: 'pg-home-wrap' })({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    padding: '10px 60px',
    backgroundcolor: 'gainsboro'
});
//#endregion
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    minHeight: 300,
    color: theme.palette.text.secondary,
}));


export default function Home({ userData }) {
    const { projectId } = useParams();
    const { userId } = userData;
    const [isNameFocused, setIsNamedFocused] = React.useState(false);
    const [name, setName] = useState('');
    const [project, setProject] = useState([]);
    const [printers, setPrinters] = useState([]);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        getProject();
        getPrinters();
        // getFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    const getProject = () => {
        let url = '/api/project/' + projectId;
        fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(function (res) {
                return res.json();
            })
            .then(async resp => {
                setProject(resp);
                setName(resp.name);
                if (resp.files) {
                    let filesList = [];
                    for (let file of resp.files) {
                        filesList.push(await getfileData(file));
                    }
                    setFiles(filesList);
                }
            });

    }


    const getPrinters = () => {
        let url = `/api/customerprinter/user/${userId}`
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            return res.json();
        }).then(resp => {
            setPrinters(resp);
        }).catch(e => {
            console.error(e);
        });

    }

    const getfileData = (file) => {
        let url = '/api/file/' + file.id;
        return fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(function (res) {
                return res.json();
            })
            .then(file => {
                file.projectId = projectId;
                return file;
            });
    }

    const saveName = (v) => {
        console.log(v)
        setIsNamedFocused(false)
    }

    const imageError = (e) => {
        let index = Number(e.target.dataset.index);
        index++;
        if (index < project.files.length) {
            e.target.src = '/api/file/' + project.files[index].id + '/preview'
            e.target.dataset.index = index;
        } else {
            e.target.src = '/mstile-144x144.png'
            e.target.onerror = null;
        }
    }

    return (
        <Wrapper>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                <Grid item xs={4}>
                    <Item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={project.files ? '/api/file/' + project.files[0].id + '/preview' : '/mstile-144x144.png'} data-index="0" onError={imageError} alt="preview" />
                    </Item>
                </Grid>
                <Grid item xs={8}>
                    <Item sx={{ paddingTop: 5 }}>
                        {!isNameFocused ? (
                            <Typography
                                onClick={() => {
                                    setIsNamedFocused(true);
                                }}
                                variant="h1"
                            >
                                {name}
                            </Typography>
                        ) : (
                            <TextField
                                autoFocus
                                inputProps={{}}
                                value={name}
                                onChange={event => setName(event.target.value)}
                                onBlur={event => saveName(event.target.value)}
                            />
                        )}
                        <br />
                        <Typography varient="h3">
                            {project.description}
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item><FileExplorer files={files} onChange={getProject} printers={printers} /></Item>
                </Grid>
            </Grid>
        </Wrapper>
    );
}


