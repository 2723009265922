import React from 'react';
// Material UI
import { createTheme, ThemeProvider } from '@mui/material/styles';
import customTheme from './theme.json';
import theme from './themeGenerator.js';
// React Router
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Page imports
import Home from './pages/home';
import LoginPg from './pages/login';
import Register from './pages/register';
import Slicer from './pages/slicer';
import Project from './pages/project';
// Component imports
import TopNav from './components/topNav';
import RoutesWrapper from './components/routesWrapper';
import Landing from './pages/landing';
import Printers from './pages/printers';

const AppRoutes = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <TopNav {...props} />
        <RoutesWrapper>
          <Routes>
            <Route index element={<Home {...props} />} />
            <Route path='/' element={<Home {...props} />} />
            <Route path='/slicer' element={<Slicer {...props} />} />
            <Route path='/slicer/:projectId' element={<Slicer {...props}/>} />
            <Route path='/project/:projectId' element={<Project {...props}/>} />
            <Route path='/printers' element={<Printers {...props} />} />
          </Routes>
        </RoutesWrapper>
      </Router>
    </ThemeProvider>
  );
};

const UnsecuredRoutes = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route index element={<Landing {...props} />} />
          <Route path='/' element={<Landing {...props} />} />
          <Route path='/register' element={<Register {...props} />} />
          <Route path='/login' element={<LoginPg {...props} />} />
          {/*  <Route path='/login' element={<Slicer {...props} />} />
            <Route path='/register/' element={<Slicer {...props}/>} /> */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export { AppRoutes, UnsecuredRoutes };
