import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';

export default function ProjectCard({ project, onChange = () => { } }) {
    return (
        <Card sx={{
            position: 'relative', maxWidth: 350, maxHeight: 350, margin: '0 10px', borderRadius: 5, background: 'none', color: 'white', marginBottom: '4rem', border: '1px solid white', boxShadow: 'none'
        }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings">
                        {/* <MoreVertIcon /> */}
                    </IconButton>
                }
                title={"New Project"}
                sx={{ position: 'absolute', width: '100%', top: 10, left: 0, padding: '10px 25px', opacity: 1, textAlign: 'center' }}
            />
            <Link to={'/?upload=true'}>
                <CardMedia
                    component="img"
                    height="194"
                    image={`/images/blank.png`}
                    alt="New Project"
                    style={{ objectFit: 'contain', height: '100%', padding: 50 }}
                />
            </Link>
            <Link to={'/?upload=true'} style={{ position: 'absolute', display: 'flex', left: 0, top: 0, width: '100%', height: '100%' }}>
                <AddIcon sx={{ alignSelf: 'center', margin: 'auto', color: 'white', fontSize: "10rem" }} />
            </Link>
            <CardContent sx={{ position: 'absolute', width: '100%', bottom: 10, left: 0, padding: '10px 25px', opacity: 1, textAlign: 'center' }}>
                <Typography variant="h3" color="white">
                    Create a new Project
                </Typography>
            </CardContent>
        </Card>
    );
}