const io = require("socket.io-client");

let singleton;
const init = (host,jwt) => {

    if(singleton) {
        return singleton;
    }
    const wsUrl = window.location.origin.replace(':3000',':10112');
    const opts = {
        reconnectionDelayMax: 10000,
        path: '/api/status/sockets/',
        reconnection: true,
        extraHeaders: {
            jwt,
        },
        upgrade:false,
        transports:process.env.NODE_ENV !== 'production'
        ? ['polling']
        : ['polling', 'websocket'], //cannot proxy more that one ws using the hpm so resort to polling in dev.
    };

    if(process.env.NODE_ENV !== 'production') {
        opts.protocols = ["http"];
    }

    singleton = io(wsUrl, opts);
    return singleton;
  };

  module.exports = {init}