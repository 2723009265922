/** @format */

import React, { useState, useRef, useEffect } from 'react'
import Draggable from 'react-draggable';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { SliderObj, SliderComp } from '@sunsh1n3/slicer-comps';

const Styles = {
    root: {
        position: 'absolute',
        display: 'flex',
        top: 40,
        right: 40,
        maxWidth: 350,
        maxHeight: "75vh",
        overflow: "scroll",
        zIndex: 100,
        flexDirection: 'column'
    },
    header: {
        width: '100%',
        backgroundColor: 'black',
        color: 'white',
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
        fontSize: '2rem',
        height: '8rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4rem',

    },
    divider: {
        width: '100%',
        // height: '4rem',
        display: 'flex',
        fontSize: '2rem',
        backgroundColor: '#d8d8d8',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem 2rem',
        color: 'black'
    },
    odd: {
        backgroundColor: '#e6e6e6',
    },
    footer: {
        width: '100%',
        height: '1.5rem',
        backgroundColor: '#d8d8d8',
        borderBottomLeftRadius: 25,
        borderBottomRightRadius: 25,
    },
    tools: {
        width: '100%',
        alignSelf: 'center',
        margin: 'auto',
        color: 'white',
        zIndex: 100,
        '& p': {
            color: 'black'
        }
    },
    button: {
        color: 'black',
        borderColor: 'black',
        margin: '.25em .5em',
    },
    toolTip: {
        fontSize: '2em'
    },
    active: {
        borderColor: '#174CFF',
    }
};


const Resize = ({ onChange = () => { }, modelVolume = [0, 0, 0], show = false }) => {
    const [values, setValues] = useState([100, 100, 100]);
    const [constrain, setContstrain] = useState(true);
    const [localVolumeX, setLocalVolumeX] = useState(modelVolume[0]);
    const [localVolumeY, setLocalVolumeY] = useState(modelVolume[1]);
    const [localVolumeZ, setLocalVolumeZ] = useState(modelVolume[2]);
    const updating = useRef(false);
    const handleChange = (newValue) => {
        let v = newValue;
        setValues([v, v, v]);

        setLocalVolumeX(modelVolume[0] * v / 100);
        setLocalVolumeY(modelVolume[1] * v / 100);
        setLocalVolumeZ(modelVolume[2] * v / 100);
    };

    const handleChangeAxis = axis => (newValue) => {
        // let n = exponentialFactor(newValue);
        let v = newValue;
        let tmp = [...values];
        if (axis === 'x') {
            tmp[0] = v;
        } else if (axis === 'y') {
            tmp[1] = v;
        } else {
            tmp[2] = v;
        }
        setValues(tmp);

        setLocalVolumeX(modelVolume[0] * tmp[0] / 100);
        setLocalVolumeY(modelVolume[1] * tmp[1] / 100);
        setLocalVolumeZ(modelVolume[2] * tmp[2] / 100);
    };

    const reset = () => {
        setValues([100, 100, 100]);
    }

    useEffect(() => {
        onChange([values[0] / 100, values[1] / 100, values[2] / 100]);
    }, [values]);


    const blurX = (e) => {
        let v = Number(e.target.value);
        let tmp = [...values];
        console.log(localVolumeX, v, modelVolume[0])
        let scale = v / modelVolume[0] * 100;
        tmp[0] = scale;
        if (constrain === true) {
            tmp = [scale, scale, scale];
        }
        setLocalVolumeX(modelVolume[0] * tmp[0] / 100);
        setLocalVolumeY(modelVolume[1] * tmp[1] / 100);
        setLocalVolumeZ(modelVolume[2] * tmp[2] / 100);
        setValues(tmp);
    }

    const blurY = (e) => {
        let v = Number(e.target.value);
        let tmp = [...values];
        let scale = v / modelVolume[1] * 100;
        tmp[1] = scale;
        if (constrain === true) {
            tmp = [scale, scale, scale];
        }
        setLocalVolumeX(tmp[0] / 100 * modelVolume[0]);
        setLocalVolumeY(tmp[1] / 100 * modelVolume[1]);
        setLocalVolumeZ(tmp[2] / 100 * modelVolume[2]);
        setValues(tmp);
    }

    const blurZ = (e) => {
        let v = Number(e.target.value);
        let tmp = [...values];
        let scale = v / modelVolume[2] * 100;
        tmp[2] = scale;
        if (constrain === true) {
            tmp = [scale, scale, scale];
        }
        setLocalVolumeX(modelVolume[0] * tmp[0] / 100);
        setLocalVolumeY(modelVolume[1] * tmp[1] / 100);
        setLocalVolumeZ(modelVolume[2] * tmp[2] / 100);
        setValues(tmp);
    }


    return (
        <Draggable handle=".resize_handle">
            <div style={Styles.root} >
                <div style={Styles.header} className="resize_handle"><span>Resize</span></div>
                <Box sx={Styles.tools}>
                    <div style={Styles.divider}>
                        <Typography variant="body">
                            Model Size
                        </Typography>
                    </div>
                    <div style={Styles.divider}>
                        <br /><br />
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField id="standard-basic" type="number" name="pos" inputProps={{ 'data-index': 0 }} value={localVolumeX.toFixed(2)} onChange={(event) => setLocalVolumeX(Number(event.target.value))} onBlur={blurX} label="X-mm" variant="standard" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="standard-basic" type="number" name="pos" inputProps={{ 'data-index': 1 }} value={localVolumeY.toFixed(2)} onChange={(event) => setLocalVolumeY(Number(event.target.value))} onBlur={blurY} label="Y-mm" variant="standard" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="standard-basic" type="number" name="pos" inputProps={{ 'data-index': 2 }} value={localVolumeZ.toFixed(2)} onChange={(event) => setLocalVolumeZ(Number(event.target.value))} onBlur={blurZ} label="Z-mm" variant="standard" />
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ ...Styles.divider, ...Styles.odd }}>
                        <FormControlLabel
                            value="Scale"
                            label="Uniform Scaling"
                            control={<Checkbox
                                checked={constrain}
                                color="default"
                                sx={{ '& .MuiSvgIcon-root': { fontSize: '3rem' } }}
                                onChange={(event) => {
                                    setContstrain(event.target.checked);
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                        />
                        <Button style={Styles.button} onClick={reset} variant="outlined">Reset</Button>
                    </div>
                    {constrain === true ?
                        <SliderComp
                            value={values[0]}
                            // getAriaValueText={valuetext}
                            // valueLabelFormat={valuetext}
                            aria-labelledby="discrete-slider"
                            aria-label="cut"
                            valueLabelDisplay="auto"
                            onChangeCommit={handleChange}
                            showIcon={false}
                            // onIconClick={deleteCut(index)}
                            sliderObj={new SliderObj({ isParent: true, sliderLbl: "Scale", unitLbl: '%', initVal: values[0], valMax: 500, valMin: 0 })}
                        />
                        :
                        <>
                            <SliderComp
                                value={values[0]}
                                // getAriaValueText={valuetext}
                                // valueLabelFormat={valuetext}
                                aria-labelledby="discrete-slider"
                                aria-label="cut"
                                valueLabelDisplay="auto"
                                onChangeCommit={handleChangeAxis('x')}
                                showIcon={false}
                                // onIconClick={deleteCut(index)}
                                sliderObj={new SliderObj({ isParent: true, sliderLbl: "X", unitLbl: '%', initVal: values[0], valMax: 500, valMin: 0 })}
                            />
                            <SliderComp
                                value={values[1]}
                                // getAriaValueText={valuetext}
                                // valueLabelFormat={valuetext}
                                aria-labelledby="discrete-slider"
                                aria-label="cut"
                                valueLabelDisplay="auto"
                                onChangeCommit={handleChangeAxis('y')}
                                showIcon={false}
                                // onIconClick={deleteCut(index)}
                                sliderObj={new SliderObj({ isParent: false, sliderLbl: "Y", unitLbl: '%', initVal: values[0], valMax: 500, valMin: 0 })}
                            />
                            <SliderComp
                                value={values[2]}
                                // getAriaValueText={valuetext}
                                // valueLabelFormat={valuetext}
                                aria-labelledby="discrete-slider"
                                aria-label="cut"
                                valueLabelDisplay="auto"
                                onChangeCommit={handleChangeAxis('z')}
                                showIcon={false}
                                // onIconClick={deleteCut(index)}
                                sliderObj={new SliderObj({ isParent: true, sliderLbl: "Z", unitLbl: '%', initVal: values[0], valMax: 500, valMin: 0 })}
                            />
                        </>}
                    <div style={Styles.footer}></div>
                </Box>
            </div >
        </Draggable >
    )
}

export default Resize;